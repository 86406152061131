export function transformChapters(nodes) {
  const arrayNodes = [];
  for (const node of nodes) {
    arrayNodes.push({
      ...node,
      children: node.themeIds
    })
  }
  return arrayNodes;
}
export function transformThemes(nodes) {
  const arrayNodes = [];
  for (const node of nodes) {
    arrayNodes.push({
      ...node,
      parent: node.chapterId,
      childrenLesson: node.subThemeIds,
      childrenVideo: node.videoIds,
    })
  }
  return arrayNodes;
}
export function transformSubthemes(nodes) {
  const arrayNodes = [];
  for (const node of nodes) {
    arrayNodes.push({
      ...node,
      // tasks: node.id === 2 ? [1, 2] : null,
      parent: node.themeId,
      childrenVideo: node.videoIds
    })
  }
  return arrayNodes;
}
export function transformNodes(nodes) {
  const arrayLessons = [];
  const arrayNodes = [];
  const lessons = nodes.filter(node => node.type === "lesson");
  const others = nodes.filter(node => node.type !== "lesson");
  for (const other of others) {
    const countChildren = nodes.filter(node => node.parent === other.id).length;
    arrayNodes.push({
      ...other,
      countChildren
    });
  }
  for (const lesson of lessons) {
    const tags = lesson?.tags.split(",").filter(item => !!item).map(el => el.trim());
    const grandfather = nodes.find(item => item.id === lesson.parent)?.parent;
    arrayLessons.push({
      ...lesson,
      section: grandfather,
      tags
    });
  }

  return [...arrayNodes, ...arrayLessons];
}
