import React from 'react';
import styles from './ItemCard.module.css';
import { ReactComponent as ArrowSVG } from '../../../assets/arrow.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeById } from '../../../store/themes';
import { getVideos } from '../../../store/videos';
import { getFilterStatusApp } from '../../../store/user';
import { getTasks, loadTasksList } from '../../../store/tasks';

export default function ItemCard({ item, type = "chapter", special }) {
  let countFiltredTasks = 0;
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const parent = useSelector(getThemeById(item?.themeId));
  const videoList = useSelector(getVideos());
  const videos = videoList?.filter(video => video?.subThemeId === Number(item.id));
  const filterApp = useSelector(getFilterStatusApp());
  const countFiltredVideos = videos?.filter(video => video?.classGrade.includes(filterApp)).length;
  const { countTasksByGrade } = item;
  if (filterApp && filterApp !== "all" && type === "subtheme" && countTasksByGrade && (Object.keys(countTasksByGrade) !== 0)) {
    countFiltredTasks = countTasksByGrade[String(filterApp)];
  } else {
    countFiltredTasks = 0;
  }
  // const taskList = useSelector(getTasks());
  // const tasks = taskList?.filter(video => video?.subThemeId === Number(item.id));
  // const filterApp = useSelector(getFilterStatusApp());
  // const countFiltredVideos = videos?.filter(video => video?.classGrade.includes(filterApp)).length;
  const goToItemPage = (data) => {
    const { id, themeId } = data;
    if (type === "subtheme") {
      dispatch(loadTasksList(id));
      history.push(`/video/${parent?.chapterId}/${themeId}/${id}`, {
        from: pathname
      });
    }
    if (type === "theme") {
      const { chapterId } = data;
      history.push(`/video/${chapterId}/${id}`, {
        from: pathname
      });
    }
    if (type === "chapter") {
      history.push(`/video/${id}`, {
        from: pathname
      });
    }
  };
  return (
    <div
      onClick={() => goToItemPage(item)}
      className={`${styles.ItemCard} ${ special ? styles.special : "" }`}
    >
      <div className={styles.wrapper}>
        <h2>{item.title}</h2>
        <div
          onClick={() => goToItemPage(item)}
        >
          <ArrowSVG />
        </div>
      </div>
      {!special && item.description && <div
        className={styles.description}
        name="description"
      >
        {item.description}
      </div>}
      {type === "chapter" && <div className={styles.children}>Подтем: {item?.themeIds?.length}</div>}
      {type === "theme" && <div className={styles.children}>Подтем: {item?.subThemeIds?.length}</div>}
      {type === "subtheme" && <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div className={styles.children}><span className={styles.bold}>Уроков {filterApp === 'all' ? item?.videoIds?.length : countFiltredVideos}</span> / {item?.videoIds?.length ?? 0}</div>
        <div className={styles.children}><span className={styles.bold}>Задач {filterApp === 'all' ? item?.taskIds?.length : countFiltredTasks}</span> / {item?.taskIds?.length ?? 0}</div>
      </div>}
    </div>
  );
}
