import React, { useEffect } from 'react';
import ItemCard from '../ItemCard';
import VideoCard from '../VideoCard';
import SimpleCard from '../SimpleCard';
import TaskCard from '../TaskCard';
import styles from './ItemsList.module.css';
import MiniVideoCard from '../MiniVideoCard';
import SkeletonCard from '../SkeletonCard';

export default function ItemsList({ items, type = 'items', style, ...props }) {
  useEffect(() => {
    if (items) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    return () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  }, [items]);

  const rendrerList = (items) => {
    return items.map((item) => {
      return (
        <ItemCard
          key={item.id}
          item={item}
          {...props}
        />
      )
    });
  };
  const rendrerThemesList = (items) => {
    return items.map((item) => {
      return (
        <ItemCard
          key={item.id}
          item={item}
          type="theme"
          {...props}
        />
      )
    });
  };
  const rendrerSubThemesList = (items) => {
    return items.map((item) => {
      return (
        <ItemCard
          key={item.id}
          item={item}
          type="subtheme"
          {...props}
        />
      )
    });
  };
  const rendrerVideosList = (items) => {
    return items.map((item) => {
      return (
        <VideoCard
          key={item.id}
          item={item}
          {...props}
        />
      )
    });
  };
  const rendrerMiniLessonList = (items) => {
    return items.map((item) => {
      return (
        <MiniVideoCard
          key={item.id}
          item={item}
          {...props}
        />
      )
    });
  };
  const rendrerTaskList = (items) => {
    return items.map((item) => {
      return (
        <TaskCard
          key={item.id}
          item={item}
          {...props}
        />
      )
    });
  };
  const rendrerSimpleList = (items) => {
    return items.map((item) => {
      return (
        <SimpleCard
          key={item.label}
          item={item}
          {...props}
        />
      )
    });
  };
  const rendrerSkeletonList = (items) => {
    return items.map((item) => {
      return (
        <SkeletonCard
          key={item}
          item={item}
          {...props}
        />
      )
    });
  };
  if (items) {
    return (
      <div className={styles.ItemsList} style={style}>
        {type === 'items' && rendrerList(items)}
        {type === 'themes' && rendrerThemesList(items)}
        {type === 'subthemes' && rendrerSubThemesList(items)}
        {type === 'videos' && rendrerVideosList(items)}
        {type === 'tasks' && rendrerTaskList(items)}
        {type === 'simples' && rendrerSimpleList(items)}
        {type === 'mini' && rendrerMiniLessonList(items)}
        {type === 'skeletons' && rendrerSkeletonList(items)}
      </div>
    );
  }
}
