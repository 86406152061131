import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';

import styles from './ViewerPDF.module.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

export default function ViewerPDF({ pdf }) {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div
      className={styles.ViewerPDF}
    >
      {/* <Document
        file={pdf}
        className={styles.Document}
        // options={{ workerSrc: "/pdf.worker.js" }}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className={styles.Page}
          />
        ))}
      </Document> */}
    </div>
  )
}
