import { createSlice } from "@reduxjs/toolkit";
import taskService from "../services/task.service";
import tasks from "./tasks.json";

const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    // entities: tasks,
    entities: null,
    images: null,
    isLoading: true,
    error: null,
    dataLoaded: false
  },
  reducers: {
    tasksRequested: (state) => {
      state.isLoading = true;
    },
    tasksReceived: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    tasksRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    taskImageRequested: (state) => {
      // state.isLoading = true;
    },
    taskImageReceived: (state, action) => {
      state.images = action.payload;
      // state.dataLoaded = true;
      // state.isLoading = false;
    },
    taskImageRequestFailed: (state, action) => {
      state.error = action.payload;
      // state.isLoading = false;
    }
  }
});

const { actions, reducer: tasksReducer } = tasksSlice;
const { tasksRequested, tasksReceived, tasksRequestFailed, taskImageRequested, taskImageReceived, taskImageRequestFailed } = actions;

export const loadTasksList = (subthemeId) => async (dispatch, getState) => {
  dispatch(tasksRequested());
  try {
    const { content } = await taskService.fetchById(subthemeId);
    dispatch(tasksReceived(content));
  } catch (error) {
    dispatch(tasksRequestFailed(error.message));
  }
};
export const loadTasksByClassList = (classGrade) => async (dispatch, getState) => {
  dispatch(tasksRequested());
  try {
    const { content } = await taskService.fetchByClass(classGrade);
    dispatch(tasksReceived(content));
  } catch (error) {
    dispatch(tasksRequestFailed(error.message));
  }
};
export const loadImage = (path) => async (dispatch) => {
  dispatch(taskImageRequested());
  try {
    const { content } = await taskService.getImage(path);
    // dispatch(taskImageReceived(content));
  } catch (error) {
    dispatch(taskImageRequestFailed(error.message));
  }
};

export const getTasks = () => (state) => state.tasks.entities;
export const getTasksImages = () => (state) => state.tasks.images;
export const getTasksLoadingStatus = () => (state) =>
  state.tasks.isLoading;
export const getTasksDataStatus = () => (state) => state.tasks.dataLoaded;
export const getTaskById = (id) => (state) => {
  if (state.tasks.entities) {
    return state.tasks.entities.find((task) => task.id === Number(id));
  }
};
// export const getTasksWithoutLesson = () => (state) => {
//   if (state.tasks.entities) {
//     return state.tasks.entities.filter((node) => node.type !== "lesson" && node.name !== "Физика");
//   }
// };
export const getTasksByLessonId = (id) => (state) => {
  if (state.tasks.entities) {
    return state.tasks.entities.filter((task) => task.subthemeId === Number(id));
  }
};


export default tasksReducer;
