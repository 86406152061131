import React, { useState, useEffect } from 'react';
import styles from './Recovery.module.css';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { useDispatch } from 'react-redux';
// import { getNodeById } from '../../../store/nodes';
import { validator } from '../../../utils/validator';
import TextField from '../../common/form/TextField';
import Modal from '../Modal';
import { requestNewPassword } from '../../../store/user';

const redirectUrl = '/login/login';

export default function Recovery({ item, onChangeContent }) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const dispatch = useDispatch();
  const [data, setData] = useState({ email: "" });
  const [errors, setErrors] = useState({});
  const [isTouched, setTouched] = useState(false);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(true);
  // const loginError = useSelector(getAuthErrors());

  const goBackHandler = (value) => {
    onChangeContent(value);
    history.push(`${item.backPath}`);
  };

  const validatorConfig = {
    email: {
      isRequired: {
        message: "Пожалуйста, заполните полe"
      },
      isEmail: {
        message: "Неправильный email"
      }
    }
  };

  // useEffect(() => {
  //   setIsValid(false);
  // }, []);
  useEffect(() => {
    if (isTouched) setIsValid(validate(data, validatorConfig));
  }, [data, isTouched]);

  const validate = (data, config) => {
    const errors = validator(data, config);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // const isValid = Object.keys(errors).length === 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate(data, validatorConfig);
    if (!data) return;
    if (!isValid) return;
    dispatch(requestNewPassword({
      payload: { email: data.email },
      redirect: redirectUrl
    }));
    setShow(true);
    // const redirect = history.location.state ? history.location.state.from.pathname : "/";
    // dispatch(login({ payload: data, redirect }));
  };

  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value
    }));
  };

  return (
    <div className={styles.Recovery} >
      <div className={styles.wrapper} >
        <div className={styles.Header}>
          <div
            className={styles.HeaderButton}
            onClick={() => goBackHandler('login')}
          >
            <BackArroowIcon className={styles.BackArroowIcon} />
          </div>
        </div>
      </div>
      <h2 className={styles.Title}>{item?.name}</h2>
      <article className={styles.Description}>{item?.description}</article>
      <form onSubmit={handleSubmit} className={styles.LoginForm}>
        <TextField
          placeholder="Email"
          name="email"
          value={data.email}
          onChange={handleChange}
          // style={{ marginBottom: '12px' }}
          isError={!isValid}
          // onFocus={() => setTouched(false)}
          onBlur={() => setTouched(true)}
        />
        {!isValid && isTouched ? <p className={styles.ErrorMessage}>{Object.values(errors)[0]}</p> : null}
        <button
          style={{ marginTop: isValid ? '40px' : '20px' }}
          type="submit"
          disabled={!isValid}
          className={styles.SubmitButton}
        >Восстановить пароль</button>
      </form>
      {show &&
        <Modal
          open={show}
          onClose={() => setShow(false)}
          label="Проверьте почту"
          labelButton="Назад"
          text="Мы отправили письмо с инструкцией на ваш email"
        />}
    </div>
  );
}
