import { combineReducers, configureStore } from "@reduxjs/toolkit";
import chaptersReducer from "./chapters";
import dictionariesReducer from "./dictionaries";
import subthemesReducer from "./subthemes";
import tasksReducer from "./tasks";
import themesReducer from "./themes";
import userReducer from "./user";
import videosReducer from "./videos";

const rootReducer = combineReducers({
  tasks: tasksReducer,
  user: userReducer,
  dictionaries: dictionariesReducer,
  themes: themesReducer,
  subthemes: subthemesReducer,
  videos: videosReducer,
  chapters: chaptersReducer
});

export function createStore() {
  return configureStore({
    reducer: rootReducer
  });
}
