import { createSlice } from "@reduxjs/toolkit";
import subthemeService from "../services/subtheme.service";
import { transformSubthemes } from "../utils/transformNodes";
// import nds from "./response.json";

const subthemesSlice = createSlice({
  name: "subthemes",
  initialState: {
    entities: null,
    isLoading: true,
    isVideoList: true,
    error: null,
    dataLoaded: false
  },
  reducers: {
    subthemesRequested: (state) => {
      state.isLoading = true;
    },
    subthemesReceived: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    subthemesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.dataLoaded = false;
      state.isLoading = false;
    },
    changeStateDisplay: (state, action) => {
      state.isVideoList = action.payload;
    }
  }
});

const { actions, reducer: subthemesReducer } = subthemesSlice;
const { subthemesRequested, subthemesReceived, subthemesRequestFailed, changeStateDisplay } = actions;

export const loadSubThemesList = () => async (dispatch, getState) => {
  const { entities } = getState().themes;
  dispatch(subthemesRequested());
  try {
    const { content } = await subthemeService.fetchAll();
    const data = transformSubthemes(content);
    dispatch(subthemesReceived(data));
  } catch (error) {
    dispatch(subthemesRequestFailed(error.message));
  }
};

export const toggleDisplayList = (state) => async (dispatch) => {
  dispatch(changeStateDisplay(state));
};

export const getSubThemes = () => (state) => state.subthemes.entities;
export const getSubThemeStateDisplay = () => (state) => state.subthemes.isVideoList;
export const getSubThemesLoadingStatus = () => (state) =>
  state.subthemes.isLoading;
export const getSubThemesDataStatus = () => (state) => state.subthemes.dataLoaded;
export const getSubThemeById = (id) => (state) => {
  if (state.subthemes.entities) {
    return state.subthemes.entities.find((subtheme) => subtheme.id === Number(id));
  }
};


export default subthemesReducer;
