import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoggedIn, getUserProfileStatus, loadUserProfile } from "../../store/user";

const UserLoader = ({ children }) => {
  const dispatch = useDispatch();
  const userProfileStatus = useSelector(getUserProfileStatus());
  // const isLoggedIn = useSelector(getIsLoggedIn());

  useEffect(() => {
    if (!userProfileStatus) dispatch(loadUserProfile());
  }, [userProfileStatus, dispatch]);

  return children;
};

export default UserLoader;
