// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoPage_InfoPage__Y7nLa {
  padding-bottom: 72px;
  min-height: 100vh;
  background: var(--background, #F9F9F9);
}

.InfoPage_InfoPage__Y7nLa .InfoPage_wrapper__5qF4V {
  padding: 0px 20px 24px;
  background: var(--background, #F9F9F9);
}

.InfoPage_InfoPage__Y7nLa .InfoPage_Header__Jo0\\+e {
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}
.InfoPage_InfoPage__Y7nLa .InfoPage_HeaderButton__qi492 {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}

.InfoPage_InfoPage__Y7nLa .InfoPage_Title__EDD0d {
  color: var(--black);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px
}

.InfoPage_InfoPage__Y7nLa .InfoPage_Description__kE-BZ {

  margin-top: 16px;
  color: var(--black, #0F121B);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.InfoPage_BackArroowIcon__BMjD1 {
  width: 24px;
  height: 24px;
}

.InfoPage_InfoPage__Y7nLa .InfoPage_BackArroowIcon__BMjD1 path {
  color: var(--medium-gray);
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/InfoPage/InfoPage.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB;AACF;;AAEA;;EAEE,gBAAgB;EAChB,4BAA4B;EAC5B,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".InfoPage {\n  padding-bottom: 72px;\n  min-height: 100vh;\n  background: var(--background, #F9F9F9);\n}\n\n.InfoPage .wrapper {\n  padding: 0px 20px 24px;\n  background: var(--background, #F9F9F9);\n}\n\n.InfoPage .Header {\n  height: 44px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 8px;\n}\n.InfoPage .HeaderButton {\n  width: 30%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.InfoPage .Title {\n  color: var(--black);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px\n}\n\n.InfoPage .Description {\n\n  margin-top: 16px;\n  color: var(--black, #0F121B);\n  font-family: Open Sans;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 26px;\n}\n\n.BackArroowIcon {\n  width: 24px;\n  height: 24px;\n}\n\n.InfoPage .BackArroowIcon path {\n  color: var(--medium-gray);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InfoPage": `InfoPage_InfoPage__Y7nLa`,
	"wrapper": `InfoPage_wrapper__5qF4V`,
	"Header": `InfoPage_Header__Jo0+e`,
	"HeaderButton": `InfoPage_HeaderButton__qi492`,
	"Title": `InfoPage_Title__EDD0d`,
	"Description": `InfoPage_Description__kE-BZ`,
	"BackArroowIcon": `InfoPage_BackArroowIcon__BMjD1`
};
export default ___CSS_LOADER_EXPORT___;
