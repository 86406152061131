import React, { useState } from "react";
import classes from "./SelectField.module.css";
import { ReactComponent as CaretSvg } from "../../../../assets/select-caret.svg";
import OptionsList from "../OptionsList/OptionsList";

function SelectField({ name, value, onChange, options, style, placeholder }) {
  const [isOpen, setIsOpen] = useState(false);
  // const handleChange = (target) => {
  //   onChange({ name: name, value: target.label });
  // };
  // const clearField = () => {
  //   onChange(null);
  // };
  // const selectOption = (option) => {
  //   if (option !== value) handleChange(option);
  // };
  // const isOptionSelected = (option) => {
  //   return option === value;
  // };
  const optionChosen = options?.find(option => option?.value === value);

  return (
    <div
      className={`${classes.SelectField} ${value ? classes.selected : ""}`}
      tabIndex={0}
      onClick={() => setIsOpen(prev => !prev)}
      // onBlur={() => setIsOpen(false)}
      style={style}
      name={name}
    >
      <span className={classes.value}>{value ? optionChosen?.label : placeholder}</span>
      <CaretSvg className={classes.caret} />
      {isOpen && <OptionsList
        label={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        isOpen={isOpen}
        options={options}
        onClose={() => setIsOpen(false)}
      />}
    </div>
  );
}

export default SelectField;
