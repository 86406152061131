import React from 'react';
import styles from './InfoPage.module.css';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { useSelector } from 'react-redux';
import { getThemeById } from '../../../store/themes';
import { getSubThemeById } from '../../../store/subthemes';
import { getChapterById } from '../../../store/chapters';

export default function InfoPage() {
  const history = useHistory();
  const { chapterId, themeId, subthemeId } = useParams();
  const chapter = useSelector(getChapterById(Number(chapterId)));
  const theme = useSelector(getThemeById(Number(themeId)));
  const subtheme = useSelector(getSubThemeById(Number(subthemeId)));
  if (subtheme) {
    return (
      <div className={styles.InfoPage} >
        <div className={styles.wrapper} >
          <div className={styles.Header}>
            <div onClick={() => history.goBack()}>
              <BackArroowIcon className={styles.BackArroowIcon} />
            </div>
          </div>
          <h2 className={styles.Title}>Что такое {subtheme?.title}</h2>
          <article className={styles.Description}>{subtheme?.description}</article>
        </div>
      </div>
    )
  }
  if (theme) {
    return (
      <div className={styles.InfoPage} >
        <div className={styles.wrapper} >
          <div className={styles.Header}>
            <div
              className={styles.HeaderButton}
              onClick={() => history.push(`/video/${theme.chapterId}/${theme.id}`)}
            >
              <BackArroowIcon className={styles.BackArroowIcon} />
            </div>
          </div>
          <h2 className={styles.Title}>Что такое {theme?.title}</h2>
          <article className={styles.Description}>{theme?.description}</article>
        </div>
      </div>
    )
  }
  
  return (
    <div className={styles.InfoPage} >
      <div className={styles.wrapper} >
        <div className={styles.Header}>
          <div
            className={styles.HeaderButton}
            onClick={() => history.push(`/video/${chapter.id}`)}
          >
            <BackArroowIcon className={styles.BackArroowIcon} />
          </div>
        </div>
        <h2 className={styles.Title}>Что такое {chapter?.title}</h2>
        <article className={styles.Description}>{chapter?.description}</article>
      </div>
    </div>
  )
}
