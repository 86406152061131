// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_Main__Z1S4E {
  padding-bottom: 72px;
  min-height: 100vh;
}
.Main_Main__Z1S4E .Main_wrapper__AIKht {
  padding: 10px 20px 0;
  background: var(--background, #F9F9F9);
}

.Main_Main__Z1S4E .Main_Panel__nmuKa {
  padding: 0 20px 8px;
  position: sticky;
  top: 103px;
  z-index: 10;
  background: var(--background, #f9f9f9);
}

.Main_Main__Z1S4E .Main_Background__eWyCv {
  width: 100%;
  background: var(--black-8, rgba(15, 18, 27, 0.08));
  padding: 2px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.Main_Main__Z1S4E .Main_Btn__nHVOV {
  border-radius: 6px;
  width: 50%;
  border: none;
  background: var(--black-8, rgba(15, 18, 27, 0.08));
  color: var(--medium-gray, #808080);
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  height: 30px;
}

.Main_Main__Z1S4E .Main_Btn__nHVOV.Main_active__z30H3 {
  background: var(--primary, #1E2536);
  color: var(--white, #FFF);
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/Main/Main.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,sCAAsC;AACxC;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,kDAAkD;EAClD,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,kDAAkD;EAClD,kCAAkC;EAClC,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,yBAAyB;AAC3B","sourcesContent":[".Main {\n  padding-bottom: 72px;\n  min-height: 100vh;\n}\n.Main .wrapper {\n  padding: 10px 20px 0;\n  background: var(--background, #F9F9F9);\n}\n\n.Main .Panel {\n  padding: 0 20px 8px;\n  position: sticky;\n  top: 103px;\n  z-index: 10;\n  background: var(--background, #f9f9f9);\n}\n\n.Main .Background {\n  width: 100%;\n  background: var(--black-8, rgba(15, 18, 27, 0.08));\n  padding: 2px;\n  border-radius: 8px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10;\n}\n\n.Main .Btn {\n  border-radius: 6px;\n  width: 50%;\n  border: none;\n  background: var(--black-8, rgba(15, 18, 27, 0.08));\n  color: var(--medium-gray, #808080);\n  text-align: center;\n  font-family: 'Open Sans';\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n  height: 30px;\n}\n\n.Main .Btn.active {\n  background: var(--primary, #1E2536);\n  color: var(--white, #FFF);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": `Main_Main__Z1S4E`,
	"wrapper": `Main_wrapper__AIKht`,
	"Panel": `Main_Panel__nmuKa`,
	"Background": `Main_Background__eWyCv`,
	"Btn": `Main_Btn__nHVOV`,
	"active": `Main_active__z30H3`
};
export default ___CSS_LOADER_EXPORT___;
