export function transformCountries(countries, priority) {
  const totalArray = [];
  const filtredCountries = countries.filter(country => !!country.name)
  for (const country of filtredCountries) {

    totalArray.push({
      label: country.name,
      value: String(country.id)
    });
  }
  totalArray.sort((a, b) => a.label > b.label ? 1 : -1);
  if (priority) {
    const priorityItem = totalArray.find(c => c?.label === priority);
    if (priorityItem) {
      const filteredArray = totalArray.filter(c => c?.label !== priority);
      return [priorityItem, ...filteredArray];
    }
  }
  return totalArray;
}
