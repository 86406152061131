import httpService from "./http.service";
// import localStorageService from "./localStorage.service";

const subthemeEndpoint = "SubThemes/";

const subthemeService = {
  fetchAll: async () => {
    const { data } = await httpService.get(subthemeEndpoint);
    return data;
  }
};

export default subthemeService;
