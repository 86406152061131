import { createSlice, createAction } from "@reduxjs/toolkit";
import authService from "../services/auth.service";
import localStorageService from "../services/localStorage.service";
import userService from "../services/user.service";
import generateAuthError from "../utils/generateAuthError";
import history from "../utils/history";

const initialState = localStorageService.getAccessToken() ? {
  isLoading: false,
  error: null,
  errorAuth: null,
  auth: { userId: localStorageService.getAccessToken() },
  isLoggedIn: true,
  dataLoaded: false,
  userProfile: null,
  favoritesVideo: null,
  watchedVideo: null,
  completedTasks: null,
  filterStatus: "all"
} : {
  isLoading: false,
  error: null,
  errorAuth: null,
  auth: null,
  isLoggedIn: false,
  dataLoaded: false,
  userProfile: null,
  favoritesVideo: null,
  watchedVideo: null,
  completedTasks: null,
  filterStatus: "all"
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authRequested: (state) => {
      state.errorAuth = null;
    },
    authRequestSuccess: (state, action) => {
      state.auth = action.payload;
      state.isLoggedIn = true;
    },
    authRequestFailed: (state, action) => {
      state.errorAuth = action.payload;
    },
    userProfileRequested: (state) => {
      state.isLoading = true;
    },
    userProfileReceived: (state, action) => {
      state.userProfile = action.payload;
      state.favoritesVideo = action.payload.favouriteVideoIds;
      state.completedTasks = action.payload.completedTasksIds;
      state.watchedVideo = action.payload.viewedVideoIds;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    userProfileRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    userUpdateRequested: (state) => {
      state.isLoading = true;
    },
    userUpdateSuccess: (state, action) => {
      state.userProfile = action.payload;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    userUpdateFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    favoriteAddRequested: (state) => {
      state.isLoading = true;
    },
    favoriteAddSuccess: (state, action) => {
      if (!Array.isArray(state.favoritesVideo)) {
        state.favoritesVideo = [];
      }
      state.favoritesVideo.push(action.payload);
      state.isLoading = false;
    },
    favoriteAddFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    completeTaskAddRequested: (state) => {
      state.isLoading = true;
    },
    completeTaskAddSuccess: (state, action) => {
      if (!Array.isArray(state.completedTasks)) {
        state.completedTasks = [];
      }
      state.completedTasks.push(action.payload);
      state.isLoading = false;
    },
    completeTaskAddFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    watchedVideoAddRequested: (state) => {
      state.isLoading = true;
    },
    watchedVideoAddSuccess: (state, action) => {
      if (!Array.isArray(state.watchedVideo)) {
        state.watchedVideo = [];
      }
      state.watchedVideo.push(action.payload);
      state.isLoading = false;
    },
    watchedVideoAddFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    favoriteDeleteRequested: (state) => {
      state.isLoading = true;
    },
    favoriteDeleteSuccess: (state, action) => {
      const newState = state.favoritesVideo.filter(id => id !== action.payload);
      state.favoritesVideo = newState;
      state.isLoading = false;
    },
    favoriteDeleteFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    completedTaskDeleteRequested: (state) => {
      state.isLoading = true;
    },
    completedTaskDeleteSuccess: (state, action) => {
      const newState = state.completedTasks.filter(id => id !== action.payload);
      state.completedTasks = newState;
      state.isLoading = false;
    },
    completedTaskDeleteFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    userLoggedOut: (state) => {
      state.userProfile = null;
      state.isLoggedIn = false;
      state.auth = null;
      state.dataLoaded = false;
    },
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    }
  }
});

const { actions, reducer: userReducer } = userSlice;
const {
  userProfileRequested,
  userProfileReceived,
  userProfileRequestFailed,
  authRequested,
  authRequestSuccess,
  authRequestFailed,
  userUpdateRequested,
  userUpdateSuccess,
  userUpdateFailed,
  userLoggedOut,
  favoriteAddRequested,
  favoriteAddSuccess,
  favoriteAddFailed,
  favoriteDeleteRequested,
  favoriteDeleteSuccess,
  favoriteDeleteFailed,
  completeTaskAddRequested,
  completeTaskAddSuccess,
  completeTaskAddFailed,
  completedTaskDeleteRequested,
  completedTaskDeleteSuccess,
  completedTaskDeleteFailed,
  watchedVideoAddRequested,
  watchedVideoAddSuccess,
  watchedVideoAddFailed,
  changeFilterStatus
} = actions;

export const loadUserProfile = () => async (dispatch) => {
  dispatch(userProfileRequested());
  try {
    const { content } = await userService.get();
    dispatch(userProfileReceived(content));
  } catch (error) {
    dispatch(userProfileRequestFailed(error.message));
  }
};

export const login = ({ payload, redirect }) => async (dispatch) => {
  const { email, password } = payload;
  dispatch(authRequested());
  try {
    const { content } = await authService.login({ email, password });
    dispatch(authRequestSuccess({ userId: content.accessToken }));
    localStorageService.setTokens({
      accessToken: content.accessToken,
      refreshToken: content.refreshToken,
      expireAccess: content.accessTokenExpiresIn,
      expireRefresh: content.refreshTokenExpiresIn
    });
    history.push(redirect);
  } catch (error) {
    if (error) {
      const code  = error?.response?.data?.code;
      const status = error?.response?.status;
      // const { code } = error?.response?.data;
      // const { status } = error?.response;
      if (status === 400) {
        const errorMessage = generateAuthError(code);
        dispatch(authRequestFailed(errorMessage));
      } else {
        dispatch(authRequestFailed(error.message));
      }
    }
  }
};

export const changePassword = ({ payload, redirect }) => async (dispatch) => {
  dispatch(authRequested());
  try {
    const { content } = await authService.changePassword(payload);
    dispatch(authRequestSuccess());
    history.push(redirect);
  } catch (error) {
    if (error) {
      const data  = error?.response?.data;
      const status = error?.response?.status;
      if (status >= 400 && status < 500) {
        const errorMessage = generateAuthError(data);
        dispatch(authRequestFailed(errorMessage));
      } else {
        dispatch(authRequestFailed(error.message));
      }
    }
  }
};

export const requestNewPassword = ({ payload, redirect }) => async (dispatch) => {
  dispatch(authRequested());
  try {
    const { content } = await authService.requestNewPassword(payload);
    dispatch(authRequestSuccess());
    history.push(redirect);
  } catch (error) {
    if (error) {
      const data  = error?.response?.data;
      const status = error?.response?.status;
      if (status >= 400 && status < 500) {
        const errorMessage = generateAuthError(data);
        dispatch(authRequestFailed(errorMessage));
      } else {
        dispatch(authRequestFailed(error.message));
      }
    }
  }
};

export const loginGoogle = ({ payload, redirect }) => async (dispatch) => {
  dispatch(authRequested());
  try {
    const { content } = await authService.loginGoogle(payload);
    dispatch(authRequestSuccess({ userId: content.accessToken }));
    localStorageService.setTokens({
      accessToken: content.accessToken,
      refreshToken: content.refreshToken,
      expireAccess: content.accessTokenExpiresIn,
      expireRefresh: content.refreshTokenExpiresIn
    });
    history.push(redirect);
  } catch (error) {
    if (error) {
      // const { code } = error?.response?.data;
      // const { status } = error?.response;
      const code  = error?.response?.data?.code;
      const status = error?.response?.status;
      if (status > 400) {
        const errorMessage = generateAuthError(code);
        dispatch(authRequestFailed(errorMessage));
      } else {
        dispatch(authRequestFailed(error.message));
      }
    }
  }
};
export const loginVK = ({ payload, redirect }) => async (dispatch) => {
  dispatch(authRequested());
  try {
    const { content } = await authService.loginVK(payload);
    dispatch(authRequestSuccess({ userId: content.accessToken }));
    localStorageService.setTokens({
      accessToken: content.accessToken,
      refreshToken: content.refreshToken,
      expireAccess: content.accessTokenExpiresIn,
      expireRefresh: content.refreshTokenExpiresIn
    });
    history.push(redirect);
  } catch (error) {
    if (error) {
      const code  = error?.response?.data?.code;
      const status = error?.response?.status;
      // const { code } = error?.response?.data;
      // const { status } = error?.response;
      if (status > 400) {
        const errorMessage = generateAuthError(code);
        dispatch(authRequestFailed(errorMessage));
      } else {
        dispatch(authRequestFailed(error.message));
      }
    }
  }
};
export const loginApple = ({ payload, redirect }) => async (dispatch) => {
  dispatch(authRequested());
  try {
    const { content } = await authService.loginApple(payload);
    dispatch(authRequestSuccess({ userId: content.accessToken }));
    localStorageService.setTokens({
      accessToken: content.accessToken,
      refreshToken: content.refreshToken,
      expireAccess: content.accessTokenExpiresIn,
      expireRefresh: content.refreshTokenExpiresIn
    });
    history.push(redirect);
  } catch (error) {
    if (error) {
      const code  = error?.response?.data?.code;
      const status = error?.response?.status;
      // const { code } = error?.response?.data;
      // const { status } = error?.response;
      if (status > 400) {
        const errorMessage = generateAuthError(code);
        dispatch(authRequestFailed(errorMessage));
      } else {
        dispatch(authRequestFailed(error.message));
      }
    }
  }
};

export const signUp = ({ payload, redirect }) => async (dispatch) => {
  const { email, password } = payload;
  dispatch(authRequested());
  try {
    const { content } = await authService.register({ email, password });
    // localStorageService.setTokens(data);
    dispatch(authRequestSuccess({ userId: content.accessToken }));
    localStorageService.setTokens({
      accessToken: content.accessToken,
      refreshToken: content.refreshToken,
      expireAccess: content.accessTokenExpiresIn,
      expireRefresh: content.refreshTokenExpiresIn
    });
    history.push(redirect);
    // history.push("/");
  } catch (error) {
    if (error) {
      if (Array.isArray(error?.response?.data)) {
        const { code } = error?.response?.data[0];
        const { status } = error?.response;
        if (status === 400) {
          const errorMessage = generateAuthError(code);
          dispatch(authRequestFailed(errorMessage));
        } else {
          dispatch(authRequestFailed(error.message));
        }
      } else {
        const { code } = error?.response?.data;
        const { status } = error?.response;
        if (status === 400) {
          const errorMessage = generateAuthError(code);
          dispatch(authRequestFailed(errorMessage));
        } else {
          dispatch(authRequestFailed(error.message));
        }
      }
    }
  }
};

export const updateUserProfile = (payload) => async (dispatch) => {
  dispatch(userUpdateRequested());
  try {
    const { content } = await userService.update(payload);
    dispatch(userUpdateSuccess(content));
  } catch (error) {
    dispatch(userUpdateFailed(error.message));
  }
};
export const addFavoriteVideo = (payload) => async (dispatch) => {
  dispatch(favoriteAddRequested());
  try {
    const { content } = await userService.addFavorite(payload);
    dispatch(favoriteAddSuccess(payload.id));
  } catch (error) {
    dispatch(favoriteAddFailed(error.message));
  }
};

export const deleteFavoriteVideo = (payload) => async (dispatch) => {
  dispatch(favoriteDeleteRequested());
  try {
    const { content } = await userService.removeFavorite(payload);
    dispatch(favoriteDeleteSuccess(payload.id));
  } catch (error) {
    dispatch(favoriteDeleteFailed(error.message));
  }
};

export const addCompletedTask = (payload) => async (dispatch) => {
  dispatch(completeTaskAddRequested());
  try {
    const { content } = await userService.addCompletedTask(payload);
    dispatch(completeTaskAddSuccess(payload.id));
  } catch (error) {
    dispatch(completeTaskAddFailed(error.message));
  }
};
export const deleteCompletedTask = (payload) => async (dispatch) => {
  dispatch(completedTaskDeleteRequested());
  try {
    const { content } = await userService.removeCompleteTask(payload);
    dispatch(completedTaskDeleteSuccess(payload.id));
  } catch (error) {
    dispatch(completedTaskDeleteFailed(error.message));
  }
};

export const addWatchedVideo = (payload) => async (dispatch) => {
  dispatch(watchedVideoAddRequested());
  try {
    const { content } = await userService.addWatchedVideo(payload);
    dispatch(watchedVideoAddSuccess(payload.id));
  } catch (error) {
    dispatch(watchedVideoAddFailed(error.message));
  }
};
export const changeFilterStatusApp = (payload) => async (dispatch) => {
  dispatch(changeFilterStatus(payload));
};

export const logOut = () => (dispatch) => {
  // history.push("/login/login");
  history.push("/video");
  setTimeout(() => {
    dispatch(userLoggedOut());
    localStorageService.removeAuthData();
  }, 1000);
};

export const removeUserAccount = (payload) => async (dispatch) => {
  try {
    await userService.removeUserAccount(payload);
    logOut();
  } catch (error) {
    console.error(error);
  }
};

export const getUserProfile = () => (state) => state.user.userProfile;
export const getUserLoadingStatus = () => (state) => state.user.isLoading;
export const getIsLoggedIn = () => (state) => state.user.isLoggedIn;
export const getUserProfileStatus = () => (state) => state.user.dataLoaded;
export const getCurrentUserId = () => (state) => state.user.auth.userId;
export const getAuthErrors = () => (state) => state.user.errorAuth;
export const getFavoritesVideos = () => (state) => state.user.favoritesVideo;
export const getCompletedTasks = () => (state) => state.user.completedTasks;
export const getWatchedVideos = () => (state) => state.user.watchedVideo;
export const getFilterStatusApp = () => (state) => state.user.filterStatus;

export default userReducer;
