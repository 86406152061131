export function getYearsOfBirth(start, count) {
  const arrayOfYears = [];
  const ageLimit = 7;
  const startWithOutChildren = start - ageLimit;
  for (let i = startWithOutChildren; i > start - count; i--) {
    arrayOfYears.push({
      label: String(i),
      value: String(i)
    })
  }
  return arrayOfYears;
};