import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Search from './components/pages/Search';
import Profile from './components/pages/Profile';
import Favorities from './components/pages/Favorites';
import AppLoader from './components/hoc/appLoader';
import Content from './components/layouts/Content';
import InfoPage from './components/pages/InfoPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from './components/layouts/Login';
import OnBoarding from './components/pages/OnBoarding';
import Greeting from './components/pages/Greeting';
import { useSelector } from 'react-redux';
import { getIsLoggedIn } from './store/user';
import ProtectedRoute from './components/hoc/protectedRoute';
import TasksPage from './components/pages/TasksPage';
import UserInfo from './components/pages/UserInfo';
import SummaryPage from './components/pages/SummaryPage';
import SecurityPage from './components/pages/SecurityPage';

const client_id = "871294804564-tdmlmobbjrr0d14nul1p7osje016j1e5.apps.googleusercontent.com";

function App() {
  const isLoggedin = useSelector(getIsLoggedIn());
  return (
    <div className="App">
      <AppLoader>
        {/* <UserLoader> */}
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? client_id} >
          <Switch>
            <Route path="/onboarding" component={OnBoarding} />
            <Route path="/login/:type?" component={Login} />
            {/* <ProtectedRoute path="/greeting" component={Greeting} /> */}
            <ProtectedRoute path="/profile/changepassword" component={SecurityPage} />
            <ProtectedRoute path="/profile/userinfo" component={UserInfo} />
            <ProtectedRoute path="/profile" component={Profile} />
            <Route path="/search" component={Search} />
            <ProtectedRoute path="/favorites" component={Favorities} />
            <Route path="/tasks/:subthemeId?/:taskId?" component={TasksPage} />
            <Route path="/video/:chapterId?/:themeId?/:subthemeId?/info" component={InfoPage} />
            <Route path="/video/:chapterId?/:themeId?/:subthemeId?/:videoId?/summary" component={SummaryPage} />
            <Route path="/video/:chapterId?/:themeId?/:subthemeId?/:videoId?" component={Content} />
            <Route path="/video" exact component={Content} />
            <Route path="/" component={Content} />
          </Switch>
        </GoogleOAuthProvider>
        {/* </UserLoader> */}
      </AppLoader>
    </div>
  );
}

export default App;
