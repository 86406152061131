import { createSlice } from "@reduxjs/toolkit";
import videoService from "../services/video.service";

const videosSlice = createSlice({
  name: "videos",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
    dataLoaded: false
  },
  reducers: {
    videosRequested: (state) => {
      state.isLoading = true;
    },
    videosReceived: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    videosRequestFailed: (state, action) => {
      state.error = action.payload;
      state.dataLoaded = false;
      state.isLoading = false;
    }
  }
});

const { actions, reducer: videosReducer } = videosSlice;
const { videosRequested, videosReceived, videosRequestFailed } = actions;

export const loadVideosList = () => async (dispatch) => {
  dispatch(videosRequested());
  try {
    const { content } = await videoService.fetchAll();
    dispatch(videosReceived(content));
  } catch (error) {
    dispatch(videosRequestFailed(error.message));
  }
};

export const getVideos = () => (state) => state.videos.entities;
export const getVideosLoadingStatus = () => (state) =>
  state.videos.isLoading;
export const getVideosDataStatus = () => (state) => state.videos.dataLoaded;
export const getVideoById = (id) => (state) => {
  if (state.videos.entities) {
    return state.videos.entities.find((video) => video.id === Number(id));
  }
};


export default videosReducer;
