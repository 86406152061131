// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_Header__-72ra {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.Header_label__7VMsR {
  display: inline;
  margin-right: 25px;
  margin-left: 20px;
  overflow-wrap: break-word;
}
.Header_Header__-72ra .Header_logo__2J7K- {
  height: 128px;
  width: 128px;
}
.Header_Header__-72ra img {
  width: 100%;
  height: auto;
}

`, "",{"version":3,"sources":["webpack://./src/app/components/ui/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".Header {\n  display: flex;\n  align-items: flex-end;\n  justify-content: space-between;\n}\n\n.label {\n  display: inline;\n  margin-right: 25px;\n  margin-left: 20px;\n  overflow-wrap: break-word;\n}\n.Header .logo {\n  height: 128px;\n  width: 128px;\n}\n.Header img {\n  width: 100%;\n  height: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `Header_Header__-72ra`,
	"label": `Header_label__7VMsR`,
	"logo": `Header_logo__2J7K-`
};
export default ___CSS_LOADER_EXPORT___;
