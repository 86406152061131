import React from 'react';
import ReactDom from 'react-dom';
import styles from './Modal.module.css';

export default function Modal({ onClose, label, labelButton, text, open }) {
  if (!open) {
    return null;
  }
  return ReactDom.createPortal(
    <>
      <div className={styles.Overlay} onClick={() => onClose(false)} />
      <div className={styles.Modal} >
        <div className={styles.Wrapper}>
          <h2 className={styles.Title}>{label}</h2>
          <div className={styles.Text}>{text}</div>
        </div>
        <button
          className={styles.ModalCloseBtn}
          onClick={() => onClose(false)}
        >{labelButton}</button>
      </div>
    </>,
    document.getElementById('portal'));
}
