// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_LoginForm__4bTyn {
  background: var(--white);
  /* padding: 32px 20px; */
  width: 100%;
}

.LoginForm_LoginForm__4bTyn .LoginForm_SubmitButton__B2IeN {
  width: 100%;
  padding: 11px 16px;
  border-radius: 8px;
  background: var(--primary, #1E2536);
  color: var(--white, #FFF);
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 40px;
}

.LoginForm_LoginForm__4bTyn .LoginForm_ErrorMessage__OmCRR {
  color: var(--error, #ED1C24);
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
  margin-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/LoginForm/LoginForm.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,mCAAmC;EACnC,yBAAyB;EACzB,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".LoginForm {\n  background: var(--white);\n  /* padding: 32px 20px; */\n  width: 100%;\n}\n\n.LoginForm .SubmitButton {\n  width: 100%;\n  padding: 11px 16px;\n  border-radius: 8px;\n  background: var(--primary, #1E2536);\n  color: var(--white, #FFF);\n  text-align: center;\n  font-family: 'Open Sans';\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 26px;\n  margin-bottom: 40px;\n}\n\n.LoginForm .ErrorMessage {\n  color: var(--error, #ED1C24);\n  font-family: 'Open Sans';\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n  margin-top: 4px;\n  margin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoginForm": `LoginForm_LoginForm__4bTyn`,
	"SubmitButton": `LoginForm_SubmitButton__B2IeN`,
	"ErrorMessage": `LoginForm_ErrorMessage__OmCRR`
};
export default ___CSS_LOADER_EXPORT___;
