import React, { useState, useEffect } from 'react';
import styles from './SimpleCard.module.css';
import { ReactComponent as ArrowSVG } from '../../../assets/arrow.svg';
import { useHistory, useLocation } from 'react-router-dom';
import Confirm from '../Confirm';
import { useDispatch } from 'react-redux';
import { logOut, removeUserAccount } from '../../../store/user';

export default function SimpleCard({ item }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const [ showWindowDelete, setShowWindowDelete] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  useEffect(() => {
    if (isConfirm) goToItemPage(item);
  }, [isConfirm]);
  useEffect(() => {
    return () => {
      setConfirm(false);
    };
  }, []);
  const goToItemPage = (data) => {
    const { path, label } = data;
    if (label === "Выйти") dispatch(logOut());
    if (label === "Удалить аккаунт") {
      dispatch(removeUserAccount({}));
      // dispatch(logOut());
    }
    if ((label !== "Выйти") || (label !== "Удалить аккаунт") ) {
      history.push(path, {
        from: pathname
      });
    }
  };
  const handleConfirm = (state) => {
    setConfirm(state);
    // setShow(false);
  };
  const handleClose = (state) => {
    setShow(state);
  };
  const handleCloseWindowDelete = (state) => {
    setShowWindowDelete(state);
  };
  return (
    <div
      className={styles.SimpleCard}
    >
      <div
        className={styles.wrapper}
        onClick={(e) => {
          e.stopPropagation();
          if (item.label === "Выйти") {
            setShow(true);
          } else if (item.label === "Удалить аккаунт") {
            setShowWindowDelete(true);
          } else {
            goToItemPage(item);
          }
        }}
      >
        <h2>{item.label}</h2>
        <div
          className={styles.Icon}
          onClick={(e) => {
            e.stopPropagation();
            if (item.label === "Выйти") {
              setShow(true);
            } else if (item.label === "Удалить аккаунт") {
              setShowWindowDelete(true);
            } else {
              goToItemPage(item);
            }
          }}
        >
          <ArrowSVG />
        </div>
      </div>
      {show &&
        <Confirm
          open={show}
          onClose={handleClose}
          label="Выйти"
          labelOk="Да"
          labelCancel="Назад"
          text="Уверены, что хотите выйти?"
          onConfirm={handleConfirm}
        />}
      {showWindowDelete &&
        <Confirm
          open={showWindowDelete}
          onClose={handleCloseWindowDelete}
          label="Удалить аккаунт"
          labelOk="Да"
          labelCancel="Назад"
          text="Уверены, что хотите удалить аккаунт? Все данные будут утеряны."
          onConfirm={handleConfirm}
        />}
    </div>
  );
}
