import React from 'react';
import ReactDom from 'react-dom';
import styles from './AnswerModal.module.css';

export default function AnswerModal({ onClose, label, labelOk, labelCancel, text, open, onConfirm, labelStyle, onCancel }) {
  if (!open) {
    return null;
  }
  return ReactDom.createPortal(
    <>
      <div className={styles.Overlay} onClick={() => onClose(false)} />
      <div className={styles.AnswerModal} >
        <div className={styles.Wrapper}>
          <h2 style={labelStyle} className={styles.Title}>{label}</h2>
          <div className={styles.Text}>{text}</div>
        </div>
        <button
          className={styles.ConfirmOk}
          onClick={() => {
            onConfirm();
            onClose(false);
          }}
        >{labelOk}</button>
        <button
          className={styles.ConfirmCloseBtn}
          onClick={() => {
            onCancel()
            onClose(false);
          }}
        >{labelCancel}</button>
      </div>
    </>,
    document.getElementById('task'));
}
