import React from 'react';
import ReactDom from 'react-dom';
import styles from './Confirm.module.css';

export default function Confirm({ onClose, label, labelOk, labelCancel, text, open, onConfirm }) {
  if (!open) {
    return null;
  }
  return ReactDom.createPortal(
    <>
      <div className={styles.Overlay} onClick={() => onClose(false)} />
      <div className={styles.Confirm} >
        <div className={styles.Wrapper}>
          <h2 className={styles.Title}>{label}</h2>
          <div className={styles.Text}>{text}</div>
        </div>
        <button
          className={styles.ConfirmOk}
          onClick={() => {
            onConfirm(true);
            onClose(false);
          }}
        >{labelOk}</button>
        <button
          className={styles.ConfirmCloseBtn}
          onClick={() => {
            onConfirm(false);
            onClose(false);
          }}
        >{labelCancel}</button>
      </div>
    </>,
    document.getElementById('portal'));
}
