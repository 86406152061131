export const slides = [{
        id: 1,
        title: 'Добро пожаловать!',
        description: 'Наше приложение создано специально для того, чтобы сделать учебный процесс более интересным и эффективным. Вне зависимости от уровня ваших знаний и класса школьной программы, у нас вы найдете все необходимое для успешного изучения физики.',
        image: require('../assets/onboarding-logo.webp'),
        alt: 'Logo_getaclass',
        anchor: '',
    },
    {
        id: 2,
        title: 'Смотрите видео',
        description: 'Откройте мир физики с более чем 500 увлекательными видеороликами на GetAClass! Удобная навигация поможет вам быстро найти интересующие темы и начать учиться прямо сейчас!',
        image: require('../assets/onboarding-logo.webp'),
        alt: 'Logo_getaclass',
        anchor: '',
    },
    {
        id: 3,
        title: 'Решайте задачи',
        description: 'Не только смотрите, но и активно участвуйте в обучении! Мы предлагаем увлекательные задачи к каждому видеоуроку. Решая их, вы закрепите свои знания и лучше поймете применение физических законов на практике.',
        image: require('../assets/onboarding-logo.webp'),
        alt: 'Logo_getaclass',
        anchor: '',
    },
    {
        id: 4,
        title: 'Следите за прогрессом',
        description: 'Команда GetAClass ценит ваш прогресс! Отслеживайте свой рост, ставьте новые цели и уверенно идите к успеху в изучении физики с нашим приложением!',
        image: require('../assets/onboarding-logo.webp'),
        alt: 'Logo_getaclass',
        anchor: '',
    },
    {
        id: 5,
        title: 'Наши партнеры',
        description: 'Приложение создано при поддержке правительства Новосибирской области',
        image: require('../assets/gerbnso.png'),
        alt: 'gerb_NSO',
        anchor: '',
    },
];