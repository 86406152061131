import { createSlice } from "@reduxjs/toolkit";
import chapterService from "../services/chapter.service";
import { transformChapters } from "../utils/transformNodes";
// import nds from "./response.json";

const chaptersSlice = createSlice({
  name: "chapters",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
    dataLoaded: false
  },
  reducers: {
    chaptersRequested: (state) => {
      state.isLoading = true;
    },
    chaptersReceived: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    chaptersRequestFailed: (state, action) => {
      state.error = action.payload;
      state.dataLoaded = false;
      state.isLoading = false;
    }
  }
});

const { actions, reducer: chaptersReducer } = chaptersSlice;
const { chaptersRequested, chaptersReceived, chaptersRequestFailed } = actions;

export const loadChaptersList = () => async (dispatch) => {
  dispatch(chaptersRequested());
  try {
    const { content } = await chapterService.fetchAll();
    const withoutPhisics = content.filter(chapter => chapter.title.toLowerCase() !== "физика");
    const data = transformChapters(withoutPhisics);
    dispatch(chaptersReceived(data));
  } catch (error) {
    dispatch(chaptersRequestFailed(error.message));
  }
};

export const getChapters = () => (state) => state.chapters.entities;
export const getChaptersLoadingStatus = () => (state) =>
  state.chapters.isLoading;
export const getChaptersDataStatus = () => (state) => state.chapters.dataLoaded;
export const getChapterById = (id) => (state) => {
  if (state.chapters.entities) {
    return state.chapters.entities.find((chapter) => chapter.id === Number(id));
  }
};


export default chaptersReducer;
