import React, { useEffect, useState } from 'react';
import styles from './SecurityPage.module.css';

import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import SimpleCard from '../../ui/SimpleCard';
import { securityItems } from '../../../utils/profileItems';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, getAuthErrors } from '../../../store/user';
import { validator } from '../../../utils/validator';
import TextField from '../../common/form/TextField';

const RedirectUrl = '/profile/security';
const initialState = { currentPassword: "", newPassword: "", repeatPassword: "" };

export default function SecurityPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  // const { typeChange } = useParams();
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isTouched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const loginError = useSelector(getAuthErrors());

  const validatorConfig = {
    currentPassword: {
      isRequired: {
        message: "Пожалуйста, заполните поля"
      },
      isCapitalSymbol: {
        message: "В пароле должна быть одна заглавная буква"
      },
      isContainDigit: {
        message: "Пароль должен содержать хотя бы одну цифру"
      },
      minLength: {
        message: "Пароль должен состоять минимум из 8 символов",
        value: 8
      },
      maxLength: {
        message: "Пароль должен состоять максимум из 25 символов",
        value: 25
      }
    },
    newPassword: {
      isRequired: {
        message: "Пожалуйста, заполните поля"
      },
      isCapitalSymbol: {
        message: "В пароле должна быть одна заглавная буква"
      },
      isContainDigit: {
        message: "Пароль должен содержать хотя бы одну цифру"
      },
      minLength: {
        message: "Пароль должен состоять минимум из 8 символов",
        value: 8
      },
      maxLength: {
        message: "Пароль должен состоять максимум из 25 символов",
        value: 25
      }
    },
    repeatPassword: {
      isRequired: {
        message: "Пожалуйста, заполните поля"
      },
      isCapitalSymbol: {
        message: "В пароле должна быть одна заглавная буква"
      },
      isContainDigit: {
        message: "Пароль должен содержать хотя бы одну цифру"
      },
      minLength: {
        message: "Пароль должен состоять минимум из 8 символов",
        value: 8
      },
      maxLength: {
        message: "Пароль должен состоять максимум из 25 символов",
        value: 25
      }
    }
  };

  const compareConfig = [
    { newPassword: "Пароли не совпадают" }
  ];

  useEffect(() => {
    if (isTouched) setIsValid(validate(data, validatorConfig) && isEqualPasswordFields(data, compareConfig));
  }, [data, isTouched]);
  // useEffect(() => {
  //   if (isTouched) isEqualPasswordFields(data, compareConfig);
  // }, [data.newPassword, data.repeatPassword, isTouched]);

  const validate = (data, config) => {
    const errors = validator(data, config);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isEqual = (value1, value2) => {
    return value1 === value2;
  }

  const isEqualPasswordFields = (data, config) => {
    if (!data.newPassword) return;
    if (!data.repeatPassword) return;
    if (!isEqual(data.newPassword, data.repeatPassword)) {
      setErrors(config[0]);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data) return;
    if (!isValid) return;
    if (!isEqual) return;
    dispatch(changePassword({
      payload: {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword
      },
      redirect: RedirectUrl
    }));
    setData(initialState);
    setTouched(false);
    setIsValid(false);
  };

  const goToBackWithCondition = () => {
    history.push('/profile');
  };

  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value
    }));
    setTouched(true);
  };
  const onToggle = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className={styles.SecurityPage}>
      <div className={styles.Header}>
        <div
          className={styles.BackArroowIcon}
          onClick={goToBackWithCondition}
        >
          <BackArroowIcon />
        </div>
        <div className={styles.Title}>Изменение пароля</div>
        <div></div>
      </div>
      <form onSubmit={handleSubmit} className={styles.SignUpForm}>
        <TextField
          placeholder="Введите текущий пароль"
          name="currentPassword"
          value={data.currentPassword}
          onChange={handleChange}
          style={{ marginBottom: '12px' }}
          isError={!isValid && isTouched}
          // onFocus={() => setTouched(false)}
          onBlur={() => setTouched(true)}
        />
        <TextField
          placeholder="Введите новый пароль"
          type="password"
          name="newPassword"
          value={data.newPassword}
          onChange={handleChange}
          isError={!isValid && isTouched}
          style={{ marginBottom: '12px' }}
          // onFocus={() => setTouched(false)}
          onBlur={() => setTouched(true)}
          showPassword={showPassword}
          toggleShowPassword={onToggle}
        />
        <TextField
          placeholder="Повторите новый пароль"
          type="password"
          name="repeatPassword"
          value={data.repeatPassword}
          onChange={handleChange}
          isError={!isValid && isTouched}
          // onFocus={() => setTouched(false)}
          onBlur={() => setTouched(true)}
          showPassword={showPassword}
          toggleShowPassword={onToggle}
        />
        {!isValid && isTouched ? <p className={styles.ErrorMessage}>{Object.values(errors)[0]}</p> : null}
        {isValid && isTouched ? <p className={styles.ErrorMessage}>{Object.values(errors)[0]}</p> : null}
        {loginError && <p className={styles.ErrorMessage}>{loginError}</p>}
        <button
          style={{ marginTop: isValid ? '40px' : '20px' }}
          type="submit"
          disabled={!isValid}
          className={styles.SubmitButton}
        >Обновить пароль</button>
    </form>
    </div>
  );
}
