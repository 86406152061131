// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SimpleCard_SimpleCard__Pe-kl {
  background: var(--white, #FFF);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 12px;
  min-height: 58px;
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.06);
}

/* .SimpleCard::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-width: 6px;
  border-style: solid;
  border-radius: 0px 0px 12px 12px;
  border-color: var(--black-8, rgba(15, 18, 27, 0.08));
} */

.SimpleCard_wrapper__PlJkA {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SimpleCard_SimpleCard__Pe-kl .SimpleCard_Icon__t4Bkz {
  width: 24px;
  height: 24px;
}

.SimpleCard_SimpleCard__Pe-kl h2 {
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: var(--black, #0F121B);
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/SimpleCard/SimpleCard.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,gDAAgD;AAClD;;AAEA;;;;;;;;;;GAUG;;AAEH;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,4BAA4B;AAC9B","sourcesContent":[".SimpleCard {\n  background: var(--white, #FFF);\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 16px 20px;\n  margin-bottom: 16px;\n  position: relative;\n  border-radius: 12px;\n  min-height: 58px;\n  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.06);\n}\n\n/* .SimpleCard::after {\n  content: \" \";\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 100%;\n  border-width: 6px;\n  border-style: solid;\n  border-radius: 0px 0px 12px 12px;\n  border-color: var(--black-8, rgba(15, 18, 27, 0.08));\n} */\n\n.wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.SimpleCard .Icon {\n  width: 24px;\n  height: 24px;\n}\n\n.SimpleCard h2 {\n  font-family: 'Open Sans';\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 26px;\n  color: var(--black, #0F121B);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SimpleCard": `SimpleCard_SimpleCard__Pe-kl`,
	"wrapper": `SimpleCard_wrapper__PlJkA`,
	"Icon": `SimpleCard_Icon__t4Bkz`
};
export default ___CSS_LOADER_EXPORT___;
