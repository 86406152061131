import React, { useEffect, useState } from 'react';
import styles from './Greeting.module.css';

import { getYearsOfBirth } from '../../../utils/getYearsOfBirth';
import { useHistory } from 'react-router-dom';
import TextField from '../../common/form/TextField';
import SelectField from '../../common/form/SelectField';
import Confirm from '../../ui/Confirm';
import { useDispatch, useSelector } from 'react-redux';
import { getDictionaryCities, getDictionaryCitiesDataStatus, getDictionaryCitiesLoadingStatus, getDictionaryCountries, getDictionaryCountriesDataStatus, getDictionaryCountriesLoadingStatus, getDictionaryCurrentCountry, loadCitiesByCurrentCountryId, loadCitiesList, loadCountriesList } from '../../../store/dictionaries';
import { getUserProfile, updateUserProfile } from '../../../store/user';
import ItemsList from '../../ui/ItemsList';

export default function Greeting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const optionsYears = getYearsOfBirth(currentYear, 100);
  const currentUser = useSelector(getUserProfile());
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  // const [data, setData] = useState({ firstName: "", lastName: "", age: "", sex: "", country: "", city: "", occupation: "" });
  const [show, setShow] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const countries = useSelector(getDictionaryCountries());
  const cities = useSelector(getDictionaryCities());
  const currentCountry = useSelector(getDictionaryCurrentCountry());
  const countriesLoading = useSelector(getDictionaryCountriesLoadingStatus());
  const citiesLoading = useSelector(getDictionaryCitiesLoadingStatus());
  const dataCitiesLoaded = useSelector(getDictionaryCitiesDataStatus());
  const dataCountriesLoaded = useSelector(getDictionaryCountriesDataStatus());

  const validate = (data) => {
    let isExist = false;
    const values = Object.values(data);
    for (const value of values) {
      if (value) {
        isExist = true;
        break
      }
    }
    return isExist;
  };

  
  useEffect(() => {
    if (data) setLoading(false);
  }, [data]);
  useEffect(() => {
    if (data) setIsValid(validate(data));
  }, [data]);
  
  useEffect(() => {
    if (!dataCountriesLoaded) dispatch(loadCountriesList());
    if (!dataCitiesLoaded) dispatch(loadCitiesList(121));
  }, [dispatch, dataCitiesLoaded, dataCountriesLoaded]);
  useEffect(() => {
    if (isConfirm) handleSubmit();
  }, [isConfirm]);

  
  useEffect(() => {
    if (!data && currentUser && !countriesLoading && !citiesLoading) {
      if (currentUser?.firstName || currentUser?.lastName) history.replace("/video");
      setData({
        firstName: currentUser?.firstName ? currentUser?.firstName : "",
        lastName: currentUser?.surname ? currentUser?.surname : "",
        yearOfBirth: currentUser?.yearOfBirth ? String(currentUser?.yearOfBirth) : "",
        sex: currentUser?.sex ? currentUser?.sex : "",
        country: currentUser?.countryId ? String(currentUser?.countryId) : "",
        cityId: currentUser?.cityId ? String(currentUser?.cityId) : "",
        occupation: currentUser?.occupation ? currentUser?.occupation : ""
      });
    }
  }, [data, currentUser, countriesLoading, citiesLoading]);

  useEffect(() => {
    if (!isLoading && currentCountry && data?.country) {
      dispatch(loadCitiesByCurrentCountryId(data?.country));
      setData(prev => {
        return {
          ...prev,
          city: ""
        }
      });

    }
  }, [data?.country, currentCountry, dispatch, isLoading]);

  const handleChange = (target) => {
    if (target.name === 'country') {
      setData((prevState) => ({
        ...prevState,
        [target.name]: target.value,
        cityId: ""
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        [target.name]: target.value
      }));
    }
  };
  const handleConfirm = (state) => {
    setConfirm(state);
  };
  const handleSubmit = () => {
    if (!isValid) return;
    dispatch(updateUserProfile({
      firstName: data.firstName ? data.firstName : "",
      lastName: data.lastName ? data.lastName : "",
      yearOfBirth: data.yearOfBirth ? Number(data.yearOfBirth) : null,
      sex: data.sex ? data.sex : "undefined",
      cityId: data.cityId ? Number(data.cityId) : null,
      occupation: data.occupation ? data.occupation : null
    }));
    setConfirm(false);
    history.replace('/video');
  };
  return (
    <div className={styles.Greeting}>
      <div className={styles.Header}>
        <button
          className={styles.SkipButton}
          onClick={() => history.replace('/video')}
        >Пропустить</button>
      </div>
      <h2 className={styles.Title}>Познакомимся?</h2>
      <div className={styles.Description}>Нам инетересно и полезно знать больше о наших пользователях! Пожалуйста, заполните анкету. Вы можете пропустить этот шаг и вернуться к заполнению позже.</div>
      {!isLoading ? <form className={styles.ProfileInfo}>
        <TextField
          placeholder="Имя"
          type="text"
          name="firstName"
          value={data.firstName}
          onChange={handleChange}
          style={{ marginBottom: "12px" }}
        />
        <TextField
          placeholder="Фамилия"
          type="text"
          name="lastName"
          value={data.lastName}
          onChange={handleChange}
          style={{ marginBottom: "12px" }}
        />
        <SelectField
          options={optionsYears}
          placeholder="Год рождения"
          type="text"
          name="yearOfBirth"
          value={data.yearOfBirth}
          onChange={handleChange}
          style={{ marginBottom: "12px" }}
        />
        <SelectField
          placeholder="Пол"
          type="select"
          name="sex"
          options={[{ label: "Мужской", value: "male" }, { label: "Женский", value: "female" }]}
          value={data.sex}
          onChange={handleChange}
          style={{ marginBottom: "12px" }}
        />
        <SelectField
          placeholder="Страна"
          type="select"
          name="country"
          options={countries}
          value={data.country}
          onChange={handleChange}
          style={{ marginBottom: "12px" }}
        />
        <SelectField
          placeholder="Город"
          type="select"
          name="cityId"
          value={data.cityId}
          options={cities}
          onChange={handleChange}
          style={{ marginBottom: "12px" }}
        />
        <TextField
          placeholder="Род деятельности"
          type="text"
          name="occupation"
          value={data.occupation}
          onChange={handleChange}
          style={{ marginBottom: "40px" }}
        />
        <button
          type="submit"
          className={styles.Btn}
          disabled={!isValid}
          onClick={(e) => {
            e.preventDefault();
            setShow(prev => !prev);
          }}
        >Сохранить</button>
      </form> : <ItemsList items={[1, 2, 3, 4, 5, 6, 7]} type='skeletons' styleCard={{ minHeight: "48px" }}/>}
      {show &&
        <Confirm
          open={show}
          onClose={() => setShow(false)}
          label="Сохранить?"
          labelOk="Сохранить"
          labelCancel="Не сохранять"
          text="Сохранить введенную информацию?"
          onConfirm={handleConfirm}
        />}
    </div>
  );
}
