// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioField_RadioField__kuhg9 {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.RadioField_RadioField__kuhg9 .RadioField_RadioInput__d7LRd {
  margin-right: 16px;
}

.RadioField_RadioField__kuhg9 .RadioField_RadioLabel__cPVG8 {
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items:center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/form/RadioField/RadioField.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".RadioField {\n  display: flex;\n  align-items: center;\n  margin-bottom: 24px;\n}\n\n.RadioField .RadioInput {\n  margin-right: 16px;\n}\n\n.RadioField .RadioLabel {\n  color: #000;\n  font-family: 'Open Sans';\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n  display: flex;\n  align-items:center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RadioField": `RadioField_RadioField__kuhg9`,
	"RadioInput": `RadioField_RadioInput__d7LRd`,
	"RadioLabel": `RadioField_RadioLabel__cPVG8`
};
export default ___CSS_LOADER_EXPORT___;
