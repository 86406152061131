import React, { useEffect, useState } from 'react';
import styles from './MiniVideoCard.module.css';
import { ReactComponent as CheckSVG } from '../../../assets/completed-icon.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSubThemeById } from '../../../store/subthemes';
import { getThemeById } from '../../../store/themes';
import { getWatchedVideos } from '../../../store/user';

const SECONDS_IN_MINUTE = 60;
function transformDurationVideo(seconds) {
  let durationMinutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  let durationSeconds = seconds % SECONDS_IN_MINUTE;
  if (durationSeconds < 10) {
    durationSeconds = "0" + durationSeconds;
  }

  return { minutes: durationMinutes, seconds: durationSeconds }
};

export default function MiniVideoCard({ item }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const subtheme = useSelector(getSubThemeById(item?.subThemeId));
  const theme = useSelector(getThemeById(subtheme?.themeId));
  const watchedVideosList = useSelector(getWatchedVideos());
  const [isWatched, setWatched] = useState(false);
  const duration = transformDurationVideo(item.durationSeconds);
  function initializeStateWatched(watched, video) {
    const initialState = watched?.some(v => v === video?.id);
    setWatched(initialState);
  };
  useEffect(() => {
    initializeStateWatched(watchedVideosList, item);
  }, [item, watchedVideosList]);
  return (
    <div className={styles.MiniVideoCard}>
      <div
        className={styles.Thumbnail}
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/video/${theme?.chapterId}/${subtheme?.themeId}/${item?.subThemeId}/${item?.id}`, {
            from: pathname
          });
        }}
      >
        <img
          src={`https://img.youtube.com/vi/${item.urlPart}/0.jpg`}
          alt={`картинка видео ${item.title}`}
          loading="eager"
        />
      </div>
      <div className={styles.Aside}>
        <div className={styles.wrapper}>
          <label
            className={styles.Title}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/video/${theme?.chapterId}/${subtheme?.themeId}/${item?.subThemeId}/${item?.id}`, {
                from: pathname
              });
            }}
          >{item.title}</label>
          <div className={styles.Info}>
            <div className={styles.Duration}>{`${duration.minutes}:${duration.seconds}`}</div>
            {isWatched && <div className={styles.isWatched}>
              <CheckSVG />
              ПРОСМОТРЕНО
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
