// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_Navbar__zYiaY{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  padding: 4px 16px 20px;
  background: #fff;
  box-shadow: 0px -6px 20px 0px rgba(0, 0, 0, 0.12);
}
.Navbar_Navbar__zYiaY ul{
  width: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
}
.Navbar_NavItem__JE1ud {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.Navbar_NavItem__JE1ud label{
  color: var(--medium-gray);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}
.Navbar_NavItem__JE1ud svg{
  fill: none;
}
.Navbar_NavItem__JE1ud.Navbar_active__lNsLD svg circle{
  color: var(--primary);
  stroke: var(--primary);
  stroke-width: 3px;
}
.Navbar_NavItem__JE1ud.Navbar_active__lNsLD svg path{
  stroke: var(--primary);
  stroke-width: 3px;
}
.Navbar_NavItem__JE1ud.Navbar_active__lNsLD label{
  color: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ui/Navbar/Navbar.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,QAAQ;EACR,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;EAChB,iDAAiD;AACnD;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,UAAU;AACZ;AACA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".Navbar{\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1000;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 72px;\n  padding: 4px 16px 20px;\n  background: #fff;\n  box-shadow: 0px -6px 20px 0px rgba(0, 0, 0, 0.12);\n}\n.Navbar ul{\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  list-style: none;\n}\n.NavItem {\n  width: 25%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n.NavItem label{\n  color: var(--medium-gray);\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 12px;\n}\n.NavItem svg{\n  fill: none;\n}\n.NavItem.active svg circle{\n  color: var(--primary);\n  stroke: var(--primary);\n  stroke-width: 3px;\n}\n.NavItem.active svg path{\n  stroke: var(--primary);\n  stroke-width: 3px;\n}\n.NavItem.active label{\n  color: var(--primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Navbar": `Navbar_Navbar__zYiaY`,
	"NavItem": `Navbar_NavItem__JE1ud`,
	"active": `Navbar_active__lNsLD`
};
export default ___CSS_LOADER_EXPORT___;
