import React, { useState } from 'react';
import styles from './TextField.module.css';
import { ReactComponent as OpenedEye } from '../../../../assets/opened-eye.svg';
import { ReactComponent as ClosedEye } from '../../../../assets/closed-eye.svg';

export default function TextField({ label, type, name, value, onChange, error, placeholder, style, isError, onFocus, onBlur, showPassword = false, toggleShowPassword }) {
  // const getInputClasses = () => {
  //   return "form-control" + (error ? " is-invalid" : "");
  // };

  const handleChange = ({ target }) => {
    onChange({ name: target.name, value: target.value });
  };
  return (
    <>
      <div className={styles.TextField} style={style}>
        {label && <label htmlFor={name}>{label}</label>}
        <div className={styles.InputGroup}>
          <input
            type={showPassword ? "text" : type}
            id={name}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            className={`${styles.InputControl} ${isError ? styles.error : ''}`}
            autoComplete={type === "password" ? "new-password" : "off"}
            maxLength="45"
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {/* {type === "password" && (<button
            className="btn btn-outline-secondary"
            type="button"
            onClick={toggleShowPassword}
          >
            <i className={"bi bi-eye" + (showPassword ? "-slash" : "")}></i>
          </button>)} */}
          {type === "password" && value && (
            <div className={styles.InputIcon} onClick={toggleShowPassword}>
              {showPassword ? <OpenedEye /> : <ClosedEye />}
            </div>)}
          {/* {error && <div className="invalid-feedback">{error}</div>} */}
        </div>
      </div>
    </>
  )
}