// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_Profile__pgs0q {
  padding-bottom: 72px;
  min-height: 100vh;
  background: var(--background, #F9F9F9);
}

.Profile_Profile__pgs0q .Profile_Wrapper__nIDya {
  margin-top: 60px;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/Profile/Profile.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".Profile {\n  padding-bottom: 72px;\n  min-height: 100vh;\n  background: var(--background, #F9F9F9);\n}\n\n.Profile .Wrapper {\n  margin-top: 60px;\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Profile": `Profile_Profile__pgs0q`,
	"Wrapper": `Profile_Wrapper__nIDya`
};
export default ___CSS_LOADER_EXPORT___;
