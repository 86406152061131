import React from 'react';
import styles from './DifficultyBar.module.css';

function generateMessageDifficulty(number) {
  switch (number) {
    case 1:
      return "Очень простая";
    case 2:
      return "Простая";
    case 3:
      return "Средняя";
    case 4:
      return "Сложная";
    case 5:
      return "Очень сложная";
    default:
      return "Не определена";
  }
};
const gradeArray = [1, 2, 3, 4, 5];

export default function DifficultyBar({ difficulty, ...props }) {
  return (
    <div className={styles.DifficultyBar} {...props} >
      <div className={styles.Indicator}>
        {gradeArray.map(el => {
          return (<div key={el} className={`${styles.circle} ${el <= difficulty ? styles.red : ""}`}></div>);
        })}
      </div>
      <div className={styles.Description}>
        {generateMessageDifficulty(difficulty)}
      </div>
    </div>
  );
}
