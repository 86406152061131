import axios from "axios";
import httpService from "./http.service";

// const httpJSON = axios.create({
//   baseURL: "http://localhost:4200/"
// });

const taskEndpoint = "Task/";
const taskByClassEndpoint = "Task/classgrade/";

const taskService = {
  // fetchAll: async () => {
  //   const { data } = await httpJSON.get(taskEndpoint);
  //   return data;
  // },
  fetchById: async (id) => {
    const { data } = await httpService.get(taskEndpoint + id);
    return data;
  },
  fetchByClass: async (classGrade) => {
    const { data } = await httpService.get(taskByClassEndpoint + classGrade);
    return data;
  },
  getImage: async (path) => {
    const { data } = await httpService.get(path);
    return data;
  }
};

export default taskService;
