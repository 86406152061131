// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextField_TextField__VjhWn .TextField_InputGroup__IEr6p {
  position: relative;
}

.TextField_TextField__VjhWn .TextField_InputIcon__S5pgy {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.TextField_TextField__VjhWn .TextField_InputControl__oVQbh {
  min-height: 48px;
  padding: 12px 12px 12px 16px;
  border-radius: 8px;
  background: var(--input-background, #EEE);
  border: 1px solid var(--input-background, #EEE);
  outline: none;
  width: 100%;
  caret-color: var(--error, #ED1C24);
}

.TextField_TextField__VjhWn .TextField_InputControl__oVQbh.TextField_error__djDUd {
  background: var(--error-12, rgba(241, 118, 103, 0.12));
}

.TextField_TextField__VjhWn .TextField_InputControl__oVQbh.TextField_error__djDUd:focus {
  border: 1px solid var(--error, #ED1C24);
}

.TextField_TextField__VjhWn .TextField_InputControl__oVQbh:focus {
  border: 1px solid var(--black-16, rgba(15, 18, 27, 0.16));
}

.TextField_TextField__VjhWn svg path {
  fill: var(--medium-gray, #808080);
  color: var(--medium-gray, #808080);

}

.TextField_TextField__VjhWn svg circle {
  stroke: var(--medium-gray, #808080);
  fill: var(--medium-gray, #808080);
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/form/TextField/TextField.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,kBAAkB;EAClB,yCAAyC;EACzC,+CAA+C;EAC/C,aAAa;EACb,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,yDAAyD;AAC3D;;AAEA;EACE,iCAAiC;EACjC,kCAAkC;;AAEpC;;AAEA;EACE,mCAAmC;EACnC,iCAAiC;AACnC","sourcesContent":[".TextField .InputGroup {\n  position: relative;\n}\n\n.TextField .InputIcon {\n  position: absolute;\n  right: 12px;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n.TextField .InputControl {\n  min-height: 48px;\n  padding: 12px 12px 12px 16px;\n  border-radius: 8px;\n  background: var(--input-background, #EEE);\n  border: 1px solid var(--input-background, #EEE);\n  outline: none;\n  width: 100%;\n  caret-color: var(--error, #ED1C24);\n}\n\n.TextField .InputControl.error {\n  background: var(--error-12, rgba(241, 118, 103, 0.12));\n}\n\n.TextField .InputControl.error:focus {\n  border: 1px solid var(--error, #ED1C24);\n}\n\n.TextField .InputControl:focus {\n  border: 1px solid var(--black-16, rgba(15, 18, 27, 0.16));\n}\n\n.TextField svg path {\n  fill: var(--medium-gray, #808080);\n  color: var(--medium-gray, #808080);\n\n}\n\n.TextField svg circle {\n  stroke: var(--medium-gray, #808080);\n  fill: var(--medium-gray, #808080);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextField": `TextField_TextField__VjhWn`,
	"InputGroup": `TextField_InputGroup__IEr6p`,
	"InputIcon": `TextField_InputIcon__S5pgy`,
	"InputControl": `TextField_InputControl__oVQbh`,
	"error": `TextField_error__djDUd`
};
export default ___CSS_LOADER_EXPORT___;
