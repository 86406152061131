import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './Navbar.module.css';

export default function Navbar({ navItems }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [active, setActive] = useState(pathname);

  const goToActivePage = (path) => {
    history.push(path);
    setActive(path);
  };
  return (
    <nav className={styles.Navbar}>
      <ul>
        {navItems.map(item => (
          <li
            key={item.path}
            onClick={() => goToActivePage(item.path)}
            className={`${styles.NavItem} ${active.includes(item.path) ? styles.active : ""}`}
          >
            {item.icon}
            <label>{item.label}</label>
          </li>
        ))}
        {/* <li
          onClick={() => setActive(prev => !prev)}
          className={`${styles.NavItem} ${active ? styles.active : ""}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M15.75 10.5L20.47 5.78C20.5749 5.67524 20.7085 5.60392 20.8539 5.57503C20.9993 5.54615 21.15 5.561 21.2869 5.61771C21.4239 5.67442 21.541 5.77045 21.6234 5.89367C21.7058 6.01688 21.7499 6.16176 21.75 6.31V17.69C21.7499 17.8382 21.7058 17.9831 21.6234 18.1063C21.541 18.2295 21.4239 18.3256 21.2869 18.3823C21.15 18.439 20.9993 18.4538 20.8539 18.425C20.7085 18.3961 20.5749 18.3248 20.47 18.22L15.75 13.5M4.5 18.75H13.5C14.0967 18.75 14.669 18.5129 15.091 18.091C15.5129 17.669 15.75 17.0967 15.75 16.5V7.5C15.75 6.90326 15.5129 6.33097 15.091 5.90901C14.669 5.48705 14.0967 5.25 13.5 5.25H4.5C3.90326 5.25 3.33097 5.48705 2.90901 5.90901C2.48705 6.33097 2.25 6.90326 2.25 7.5V16.5C2.25 17.0967 2.48705 17.669 2.90901 18.091C3.33097 18.5129 3.90326 18.75 4.5 18.75Z" stroke="#808080" strokeWidth="1.6" strokeLinecap="round"/>
          </svg>
          <label>Видео</label>
        </li>
        <li
          onClick={() => setActive(prev => !prev)}
          className={`${styles.NavItem} ${active ? styles.active : ""}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="currentColor">
            <path d="M21.25 8.25C21.25 5.765 19.151 3.75 16.562 3.75C14.627 3.75 12.965 4.876 12.25 6.483C11.535 4.876 9.873 3.75 7.937 3.75C5.35 3.75 3.25 5.765 3.25 8.25C3.25 15.47 12.25 20.25 12.25 20.25C12.25 20.25 21.25 15.47 21.25 8.25Z" stroke="#808080" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <label>Избранное</label>
        </li>
        <li
          onClick={() => setActive(prev => !prev)}
          className={`${styles.NavItem} ${active ? styles.active : ""}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="currentColor">
            <circle cx="11.25" cy="10.5" r="6.65" stroke="#808080" strokeWidth="1.7"/>
            <path d="M16.75 16L21.75 21" stroke="#808080" strokeWidth="1.7" strokeLinecap="round"/>
          </svg>
          <label>Поиск</label>
        </li>
        <li
          onClick={() => setActive(prev => !prev)}
          className={`${styles.NavItem} ${active ? styles.active : ""}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="currentColor">
            <path d="M15.4808 6.46154C15.4808 8.3077 14.0962 9.69231 12.2501 9.69231C10.4039 9.69231 9.01929 8.3077 9.01929 6.46154C9.01929 4.61539 10.4039 3.23077 12.2501 3.23077C14.0962 3.23077 15.4808 4.61539 15.4808 6.46154Z" stroke="#808080" strokeWidth="1.6"/>
            <path d="M12.25 12.4615C7.61265 12.4615 5.28576 15.8052 4.91756 19.3095C4.85985 19.8588 5.31307 20.3077 5.86536 20.3077H18.6346C19.1869 20.3077 19.6401 19.8588 19.5824 19.3095C19.2142 15.8052 16.8873 12.4615 12.25 12.4615Z" stroke="#808080" strokeWidth="1.6" strokeLinecap="round"/>
          </svg>
          <label>Профиль</label>
        </li> */}
      </ul>
    </nav>
  )
}
