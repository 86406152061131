import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDictionaryCitiesDataStatus, getDictionaryCountriesDataStatus, getDictionaryTagsDataStatus, loadCitiesList, loadCountriesList, loadTagsList } from "../../store/dictionaries";
import { getTasksDataStatus, loadTasksList } from "../../store/tasks";
import { getChaptersDataStatus, loadChaptersList } from "../../store/chapters";
import { getThemesDataStatus, loadThemesList } from "../../store/themes";
import { getSubThemesDataStatus, loadSubThemesList } from "../../store/subthemes";
import { getVideosDataStatus, loadVideosList } from "../../store/videos";

const AppLoader = ({ children }) => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const dispatch = useDispatch();
  // const tasksDataStatus = useSelector(getTasksDataStatus());
  const countiesDataStatus = useSelector(getDictionaryCountriesDataStatus());
  const citiesDataStatus = useSelector(getDictionaryCitiesDataStatus());
  const chapterDataStatus = useSelector(getChaptersDataStatus());
  const themeDataStatus = useSelector(getThemesDataStatus());
  const subthemeDataStatus = useSelector(getSubThemesDataStatus());
  const videoDataStatus = useSelector(getVideosDataStatus());
  const tagsDataStatus = useSelector(getDictionaryTagsDataStatus());

  useEffect(() => {
    if (!chapterDataStatus) dispatch(loadChaptersList());
  }, [chapterDataStatus, dispatch]);
  useEffect(() => {
    if (!themeDataStatus) dispatch(loadThemesList());
  }, [themeDataStatus, dispatch]);
  useEffect(() => {
    if (!subthemeDataStatus) dispatch(loadSubThemesList());
  }, [subthemeDataStatus, dispatch]);
  useEffect(() => {
    if (!videoDataStatus) dispatch(loadVideosList());
  }, [videoDataStatus, dispatch]);
  useEffect(() => {
    if (!tagsDataStatus) dispatch(loadTagsList());
  }, [tagsDataStatus, dispatch]);

  // useEffect(() => {
  //   if (!citiesDataStatus) dispatch(loadCitiesList(121));
  // }, [citiesDataStatus, dispatch]);
  // useEffect(() => {
  //   if (!countiesDataStatus) dispatch(loadCountriesList());
  // }, [countiesDataStatus, dispatch]);
  // useEffect(() => {
  //   if (!tasksDataStatus) dispatch(loadTasksList());
  // }, [tasksDataStatus, dispatch]);
  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);
  // if (nodesStatusLoading) {
  //   return (
  //     <>
  //       <SkeletonSideNavBar />
  //       <Loader />
  //     </>
  //   );
  // };
  // if (!isOnline) {
  //   return (
  //     <>
  //       <SkeletonSideNavBar />
  //       <ErrorPage label="Нет сети" errorMessage="Проверьте соединение с интернетом" source={notConnection}/>;
  //     </>
  //   );
  // };

  return children;
};

export default AppLoader;
