// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Favorites_Favorites__yukPe {
  padding-bottom: 72px;
  min-height: 100vh;
  background: var(--background, #F9F9F9);
}

.Favorites_Favorites__yukPe .Favorites_Wrapper__JKZYd {
  margin-top: 32px;
  padding: 0 20px;
  background: var(--background, #F9F9F9);
}

.Favorites_Favorites__yukPe .Favorites_Text__8DYRR {
  color: var(--black-80, rgba(15, 18, 27, 0.80));
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/Favorites/Favorites.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,8CAA8C;EAC9C,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".Favorites {\n  padding-bottom: 72px;\n  min-height: 100vh;\n  background: var(--background, #F9F9F9);\n}\n\n.Favorites .Wrapper {\n  margin-top: 32px;\n  padding: 0 20px;\n  background: var(--background, #F9F9F9);\n}\n\n.Favorites .Text {\n  color: var(--black-80, rgba(15, 18, 27, 0.80));\n  font-family: 'Open Sans';\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Favorites": `Favorites_Favorites__yukPe`,
	"Wrapper": `Favorites_Wrapper__JKZYd`,
	"Text": `Favorites_Text__8DYRR`
};
export default ___CSS_LOADER_EXPORT___;
