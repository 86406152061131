import axios from "axios";
import configFile from "../config.json";
import httpService from "./http.service";
import localStorageService from "./localStorage.service";


const signUpEndpoint = "Identity/register/";
const loginEndpoint = "Identity/signin/";
const refreshEndpoint = "Identity/refresh/";
const googleEndpoint = "Identity/signin-oauth/Google";
const vkEndpoint = "Identity/signin-oauth/Vk";
const appleEndpoint = "Identity/signin-oauth/Apple";
const changePassEndpoint = "Identity/change-password";
const requestNewPassEndpoint = "Identity/request-new-password";

const httpAuth = axios.create({
  baseURL: configFile.apiEndpoint
});

const authService = {
  register: async (payload) => {
    const { data } = await httpService.post(signUpEndpoint, payload);
    return data;
  },
  login: async (payload) => {
    const { data } = await httpService.post(loginEndpoint, payload);
    return data;
  },
  changePassword: async (payload) => {
    const { data } = await httpService.post(changePassEndpoint, payload);
    return data;
  },
  requestNewPassword: async (payload) => {
    const { data } = await httpService.post(requestNewPassEndpoint, payload);
    return data;
  },
  loginGoogle: async (payload) => {
    const { data } = await httpService.get(googleEndpoint, {
      params: {
        authorizationCode: payload
      }
    });
    return data;
  },
  loginVK: async (payload) => {
    const { data } = await httpService.get(vkEndpoint, {
      params: {
        authorizationCode: payload
      }
    });
    return data;
  },
  loginApple: async (payload) => {
    const { data } = await httpService.get(appleEndpoint, {
      params: {
        jwtToken: payload
      }
    });
    return data;
  },
  refresh: async (refresh) => {
    const { data } = await httpAuth.post(refreshEndpoint, {
      refreshToken: localStorageService.getRefreshToken()
    });
    return data;
  }
};

export default authService;
