export const privacyData = {
  name: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ',
  description: 'Настоящая Политика конфиденциальности персональных данных (далее — «Политика») действует в отношении всей информации, которую ООО «Новая школа» (ИНН 5408024656, адрес места нахождения: 630090, г. Новосибирск, пр-кт Академика Лаврентьева далее – «Оператор») может получить от Пользователя приложения для мобильных устройств (смартфонов) и персональных компьютеров «GetAClass» (далее — «Приложение»). Регистрация в Приложении и использование Приложения означают безоговорочное согласие Пользователя с Политикой и указанными в ней условиями обработки его персональных данных. В случае несогласия с этими условиями Пользователь должен воздержаться от использования Приложения, а также отказаться от присоединения к данной Политике, Пользовательскому соглашению и Согласию на обработку персональных данных.',
  backPath: '/login/register',
  elements: [
    {
      id: 1,
      title: "ПЕРСОНАЛЬНЫЕ ДАННЫЕ ПОЛЬЗОВАТЕЛЕЙ, ОБРАБАТЫВАЕМЫЕ ОПЕРАТОРОМ",
      points: [
        'В рамках Политики под «персональными данными Пользователя» понимаются:',
        'Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учётной записи) в Приложении или в процессе его использования, включая персональные данные Пользователя.',
        'Иная информация о Пользователе, сбор и/или предоставление которой определены в регулирующих функционирование Приложения документах (при их наличии).',
        'Политика применима только к Приложению. Оператор не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным в Приложении. На таких сайтах у Пользователя может собираться или запрашиваться иная персональная информация, а также могут совершаться иные действия.',
        'Оператор не проверяет достоверность, точность и полноту персональных данных, предоставляемых Пользователем, и не осуществляет контроль их дееспособности. Однако Оператор исходит из того, что Пользователь, обладая полной дееспособностью, предоставляет достоверные, точные и достаточные персональные данные, необходимые Приложению, и поддерживает эту информацию в актуальном состоянии.',
        'В Политике под «обработкой персональных данных» Оператор и Пользователь понимают весь перечень действий с персональными данными, предусмотренный ФЗ N 152-ФЗ "О персональных данных", а также указанный в Согласии на обработку персональных данных Пользователя. '
      ]
    },
    {
      id: 2,
      title: "ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ",
      points: [
        'Оператор обрабатывает только те персональные данные Пользователя, которые необходимы для использования Приложения и исполнения Оператором принятых на себя обязательств. Оператор не обрабатывает персональные данные, которые прямо не связаны или не требуются для выполнения целей обработки персональных данных, упомянутых в Политике, в Пользовательском соглашении, в Согласии на обработку персональных данных Пользователя и/или в соответствующих уведомлениях, направленных Пользователю.',
        'Обработка персональных данных Пользователя осуществляется Оператором на основании Согласия на обработку персональных данных Пользователя. '
      ]
    },
    {
      id: 3,
      title: "УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ И ИХ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ",
      points: [
        'Оператор обрабатывает персональные данные Пользователя в соответствии с условиями, установленными в Политике, Пользовательском соглашении, Согласии на обработку персональных данных Пользователя, а также в соответствии с внутренним регламентом работы Приложения.',
        'В отношении Персональных данных Пользователя Оператором сохраняется их конфиденциальность.',
        'Оператор вправе передать персональные данные Пользователя третьим лицам в следующих случаях:',
        'Пользователь выразил свое согласие на передачу (распространение) своих персональных данных партнерам Оператора, если такая передача необходима в рамках использования Пользователем Приложения;',
        'Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;',
        'Такая передача происходит в рамках реорганизации, продажи или иной передачи деятельности (полностью или в части) Оператора, при этом к приобретателю переходят все обязательства по соблюдению условий Политики применительно к полученным им персональным данным Пользователей;',
        'В целях обеспечения возможности защиты прав и законных интересов Оператора или третьих лиц в случаях, когда Пользователь нарушает условия Политики, Пользовательского соглашения или иные свои обязательства, связанные с использованием Приложения.',
        'При обработке персональных данных Пользователей Оператор руководствуется Федеральным законом РФ № 152-ФЗ от 27.06.2006 «О персональных данных».',
        'Обработка (в т.ч. хранение) персональных данных (в том числе обезличенных) осуществляется на территории Российской федерации на серверах ООО "РТКлауд" (юридический адрес: 127427, г. Москва, ул. Академика Королева, д 12, этаж 1, пом. IC, комната 4, ИНН 7729443821).'
      ]
    },
    {
      id: 4,
      title: "МЕРЫ, ПРИМЕНЯЕМЫЕ ДЛЯ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ",
      points: [
        'Оператор принимает необходимые и достаточные организационные и технические меры для защиты персональных данных Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц. Оператором принимаются следующие меры:',
        'назначение из числа сотрудников Оператора лиц, ответственных за организацию обработки и защиты персональных данных;',
        'ограничение состава лиц, имеющих доступ к персональным данным;',
        'организация учета, хранения и обращения носителей информации;',
        'определение угроз безопасности персональных данных при их обработке;',
        'проверка готовности и эффективности использования средств защиты информации;',
        'разграничение доступа Пользователей к информационным ресурсам и программно-аппаратным средствам обработки информации;',
        'организация пропускного режима на территорию Оператора, охраны помещений с техническими средствами обработки персональных данных;',
        'использование технических и организационных мер безопасности, в том числе инструментов шифрования и проверки подлинности для надежного хранения персональных данных;',
        'использование безопасных сетей и доступ к ним ограниченного количества лиц, обладающих специальными правами доступа;',
        'осуществление резервного копирования персональных данных.',
        'Персональные данные, которые обрабатывает в рамках функционирования Приложения Оператор, хранятся в России. Персональные данные хранятся в течение всего времени, необходимого для достижения целей, в соответствии с которыми они собраны, кроме случаев, когда законодательством установлено иное.'
      ]
    },
    {
      id: 5,
      title: "СРОК ДЕЙСТВИЯ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ. ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО",
      points: [
        'Политика вступает в силу с момента Регистрации Пользователя в Приложении в соответствии с условиями Пользовательского соглашения. ',
        'Оператор имеет право вносить изменения в Политику путем размещения в Приложении и представления Пользователю для ознакомления ее новой редакции. Новая редакция Политики вступает в силу с момента ее размещения в Приложении, если иное не предусмотрено новой редакцией Политики. Продолжение использования Приложения после представления для ознакомления новой редакции Политики означает ознакомление и согласие Пользователя ее новой редакцией.',
        'Политика действует в течение срока обработки персональных данных Пользователя.',
        'К Политике и отношениям между Пользователем и Оператором, возникающим в связи с применением Политики, подлежит применению право Российской Федерации.'
      ]
    },
  ]
};