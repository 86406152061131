import React, { useEffect, useRef } from "react";
import styles from "./SearchField.module.css";
import { ReactComponent as SearchIcon } from '../../../../assets/search-field-icon.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/cancel-icon.svg';

function SearchField({ placeholder = "Поиск", name, value, onChange, onCleanField, labelButton, labelAction, onGoBack, onGoAction }) {
  const inputRef = useRef(null);

  const handleFocusClick = () => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  };
  useEffect(() => {
    handleFocusClick();
  }, []);
  return (
    <div className={styles.SearchField} >
      <form className={styles.InputGroup} onClick={handleFocusClick}>
        {/* <span className="input-group-text" id="inputGroup-sizing-default">{label}</span> */}
        <div className={styles.SearchIcon}>
          <SearchIcon />
        </div>
        <input
          // style={{ marginRight: value ? "17px" : "36px" }}
          ref={inputRef}
          onChange={onChange}
          name={name}
          value={value}
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          minLength="2"
        />
        {value &&
          <div className={styles.CancelIcon} onClick={onCleanField}>
            <CancelIcon />
          </div>}
      </form>
      {labelButton && <button
        className={styles.BackButton}
        onClick={() => {
          onCleanField();
          onGoBack();
        }}
      >{labelButton}</button>}
      {labelAction && <button
        className={`${styles.BackButton} ${value ? styles.light : ""}`}
        onClick={() => {
          onGoAction();
        }}
      >{labelAction}</button>}
    </div>
  );
}

export default SearchField;
