import React, { useEffect, useState } from 'react';
import styles from './SubthemePage.module.css';
import ItemsList from '../../ui/ItemsList';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { getTasks, getTasksDataStatus, loadTasksList } from '../../../store/tasks';
import { getSubThemeById, getSubThemeStateDisplay, toggleDisplayList } from '../../../store/subthemes';
import { getVideos } from '../../../store/videos';
import { getThemeById } from '../../../store/themes';
import { getFilterStatusApp } from '../../../store/user';

export default function SubthemePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [active, setActive] = useState(buttons[0].id);
  const { chapterId, themeId, subthemeId } = useParams();
  const { pathname } = useLocation();
  const tasksDataStatus = useSelector(getTasksDataStatus());
  const subtheme = useSelector(getSubThemeById(subthemeId));
  const videoList = useSelector(getVideos());
  const videos = videoList?.filter(video => video?.subThemeId === Number(subthemeId));
  const filterApp = useSelector(getFilterStatusApp());
  const filtredVideos = videos?.filter(video => filterApp === 'all' ? video : video?.classGrade.includes(filterApp));
  const tasks = useSelector(getTasks());
  const filtredTasks = tasks?.filter(task => filterApp === 'all' ? task : task?.classGrade.includes(filterApp));
  // const filtredTasks = tasks?.filter(task => filterApp === 'all' ? task : task?.classGrade == filterApp);
  const theme = useSelector(getThemeById(subtheme?.themeId));
  const active = useSelector(getSubThemeStateDisplay());
  const [isLoading, setLoading] = useState(true);
  const state = history?.location?.state?.from ?? null;
  const goBack = () => {
    if (state) {
      history.push(state);
    } else {
      history.push(`/video/${theme.parent}/${subtheme.parent}`);
    }
  };

  useEffect(() => {
    if (!tasksDataStatus && subthemeId) dispatch(loadTasksList(subthemeId));
  }, [tasksDataStatus, subthemeId, dispatch]);
  // useEffect(() => {
  //   if (videos && tasks) setLoading(false);
  // }, [videos, tasks]);
  useEffect(() => {
    if (videos && tasks) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 700);
      return () => {
        clearTimeout(timeout);
        // setLoading(true);
      };
    }
  }, [videos, tasks]);
  // const switchContentSubsectionPage = () => {
  //   dispatch(toggleDisplayList());
  // };
  if (!subtheme) {
    return null;
  }
  return (
    <div className={styles.SubthemePage} >
      <div className={styles.Header}>
        <div
          className={styles.HeaderButton}
          onClick={goBack}
        >
          <BackArroowIcon className={styles.BackArroowIcon} />
        </div>
        <div
          className={styles.HeaderButton}
          onClick={() => history.push(`${pathname}/info`)}
        >
          <InfoIcon className={styles.InfoIcon} />
        </div>
      </div>
      <div className={styles.wrapper} >
        {subtheme && <h2 className={styles.Title}>{subtheme.title}</h2>}
      </div>
      <div className={styles.Panel} >
        <div className={styles.Background}>
          <button
            className={`${styles.Btn} ${active ? styles.active : ""}`}
            onClick={() => dispatch(toggleDisplayList(true))}
          >
            Урок
          </button>
          <button
            className={`${styles.Btn} ${!active ? styles.active : ""}`}
            onClick={() => {
              dispatch(toggleDisplayList(false));
              if (!active) dispatch(loadTasksList(subthemeId));
            }}
          >
            Задачи
          </button>
        </div>
      </div>
      <div className={styles.List} >
        {active
          ? (!isLoading)
            ? filtredVideos?.length !== 0
              ? <ItemsList items={filtredVideos} type='videos' />
              : <p>Уроков пока нет</p>
            : <ItemsList items={[1, 2]} type='skeletons' />
          : (!isLoading)
            ? filtredTasks?.length !== 0
              ? <ItemsList items={filtredTasks} type='tasks' />
              : <p>Задач пока нет</p>
            : <ItemsList items={[1, 2, 3]} type='skeletons' styleCard={{ minHeight: "174px" }}/>
        }
      </div>
    </div>
  )
}
