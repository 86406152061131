import httpService from "./http.service";

const tagsEndpoint = "/Dictionary/tags";
const counriesEndpoint = "/Dictionary/country";
const citiesEndpoint = "/Dictionary/city";

const dictionaryService = {
  getTags: async () => {
    const { data } = await httpService.get(tagsEndpoint);
    return data;
  },
  getCountries: async () => {
    const { data } = await httpService.get(counriesEndpoint);
    return data;
  },
  getCities: async (countryId) => {
    const { data } = await httpService.get(citiesEndpoint, {
      params: {
        CountryId: `${countryId}`
      }
    });
    return data;
  },
};

export default dictionaryService;
