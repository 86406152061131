import React, { useState } from 'react';
import styles from './VideoCard.module.css';
import { ReactComponent as FavoritiesSVG } from '../../../assets/favorities-icon.svg';
import { ReactComponent as CheckSVG } from '../../../assets/completed-icon.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addFavoriteVideo, deleteFavoriteVideo, getFavoritesVideos, getIsLoggedIn, getWatchedVideos } from '../../../store/user';
import { getSubThemeById } from '../../../store/subthemes';
import { getThemeById } from '../../../store/themes';

const SECONDS_IN_MINUTE = 60;
function transformDurationVideo(seconds) {
  let durationMinutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  let durationSeconds = seconds % SECONDS_IN_MINUTE;
  if (durationSeconds < 10) {
    durationSeconds = "0" + durationSeconds;
  }

  return { minutes: durationMinutes, seconds: durationSeconds }
};

export default function VideoCard({ item }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const favoritesVideosList = useSelector(getFavoritesVideos());
  const watchedVideosList = useSelector(getWatchedVideos());
  const initialState = favoritesVideosList?.some(video => video === item.id);
  const initialWatchedState = watchedVideosList?.some(video => video === item.id);
  const [isFavorites, setFavorites] = useState(initialState);
  const [isWatched, setWatched] = useState(initialWatchedState);
  const { pathname } = useLocation();
  const duration = transformDurationVideo(item.durationSeconds);
  const subtheme = useSelector(getSubThemeById(item?.subThemeId));
  const theme = useSelector(getThemeById(subtheme?.themeId));
  const isLoggedIn = useSelector(getIsLoggedIn());
  // const goToItemPage = (data) => {
  //   const { id, type, parent } = data;
  //   if (type === "subsection") {
  //     history.push(`/${parent}/${id}`);
  //   }
  //   if (type === "section") {
  //     history.push(`/${id}`);
  //   }
  // };
  const handleAddFavoriteVideo = (item) => {
    if (!isFavorites) {
      dispatch(addFavoriteVideo({id: item.id}));
      setFavorites(true);
    } else {
      dispatch(deleteFavoriteVideo({id: item.id}));
      setFavorites(false);
    }
  };
  return (
    <div className={styles.VideoCard}>
      <div
        className={styles.Thumbnail}
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/video/${theme?.chapterId}/${theme?.id}/${subtheme?.id}/${item?.id}`, {
            from: pathname
          });
        }}
      >
        <img
          src={`https://img.youtube.com/vi/${item?.urlPart}/0.jpg`}
          alt={`картинка видео ${item.title}`}
          loading="eager"
        />
      </div>
      <div className={styles.Footer}>
        <div className={styles.wrapper}>
          <label
            className={styles.Title}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/video/${theme?.chapterId}/${theme?.id}/${subtheme?.id}/${item?.id}`, {
                from: pathname
              });
            }}
          >{item.title}</label>
          <div className={styles.Info}>
            <div className={styles.Duration}>{`${duration.minutes}:${duration.seconds}` ?? "00:00"}</div>
            {isWatched && <div className={styles.isWatched}>
              <CheckSVG />
              Просмотрено
            </div>}
          </div>
        </div>
        <div className={styles.Icon} onClick={() => {
          if (isLoggedIn) {
            handleAddFavoriteVideo(item);
          }
        }}>
          <FavoritiesSVG className={`${isFavorites ? styles.Favorites : ""}`} />
        </div>
      </div>
    </div>
  );
}
