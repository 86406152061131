// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThemePage_ThemePage__icFYZ {
  padding-bottom: 72px;
  min-height: 100vh;
  background: var(--background, #F9F9F9);
}

.ThemePage_ThemePage__icFYZ .ThemePage_wrapper__KoDFc {
  padding: 0px 20px 24px;
  background: var(--background, #F9F9F9);
}

.ThemePage_ThemePage__icFYZ .ThemePage_Header__v7xqn {
  height: 44px;
  padding: 0px 20px 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background: var(--background, #f9f9f9);
  z-index: 10;
}
.ThemePage_ThemePage__icFYZ .ThemePage_HeaderButton__62rtT {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ThemePage_ThemePage__icFYZ .ThemePage_HeaderButton__62rtT:nth-child(2) {
  flex-direction: row-reverse;
}

.ThemePage_ThemePage__icFYZ .ThemePage_Title__KkyrV {
  color: var(--black);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px
}

.ThemePage_BackArroowIcon__tpLj\\+,
.ThemePage_InfoIcon__RegcD {
  width: 24px;
  height: 24px;
}

.ThemePage_ThemePage__icFYZ .ThemePage_BackArroowIcon__tpLj\\+ path {
  color: var(--medium-gray);
}

.ThemePage_ThemePage__icFYZ .ThemePage_InfoIcon__RegcD path {
  stroke: var(--medium-gray);
  fill: none;
}

.ThemePage_ThemePage__icFYZ .ThemePage_List__8tvIi {
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/ThemePage/ThemePage.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;EACtC,WAAW;AACb;AACA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB;AACF;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".ThemePage {\n  padding-bottom: 72px;\n  min-height: 100vh;\n  background: var(--background, #F9F9F9);\n}\n\n.ThemePage .wrapper {\n  padding: 0px 20px 24px;\n  background: var(--background, #F9F9F9);\n}\n\n.ThemePage .Header {\n  height: 44px;\n  padding: 0px 20px 0;\n  position: sticky;\n  top: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n  background: var(--background, #f9f9f9);\n  z-index: 10;\n}\n.ThemePage .HeaderButton {\n  width: 30%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.ThemePage .HeaderButton:nth-child(2) {\n  flex-direction: row-reverse;\n}\n\n.ThemePage .Title {\n  color: var(--black);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px\n}\n\n.BackArroowIcon,\n.InfoIcon {\n  width: 24px;\n  height: 24px;\n}\n\n.ThemePage .BackArroowIcon path {\n  color: var(--medium-gray);\n}\n\n.ThemePage .InfoIcon path {\n  stroke: var(--medium-gray);\n  fill: none;\n}\n\n.ThemePage .List {\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ThemePage": `ThemePage_ThemePage__icFYZ`,
	"wrapper": `ThemePage_wrapper__KoDFc`,
	"Header": `ThemePage_Header__v7xqn`,
	"HeaderButton": `ThemePage_HeaderButton__62rtT`,
	"Title": `ThemePage_Title__KkyrV`,
	"BackArroowIcon": `ThemePage_BackArroowIcon__tpLj+`,
	"InfoIcon": `ThemePage_InfoIcon__RegcD`,
	"List": `ThemePage_List__8tvIi`
};
export default ___CSS_LOADER_EXPORT___;
