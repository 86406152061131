import httpService from "./http.service";
// import localStorageService from "./localStorage.service";

const videoEndpoint = "Video/";

const videoService = {
  fetchAll: async () => {
    const { data } = await httpService.get(videoEndpoint);
    return data;
  }
};

export default videoService;
