// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_PrivacyPolicy__OsMca {
  /* padding-bottom: 72px; */
  min-height: 100vh;
  background: var(--background, #F9F9F9);
}

.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_wrapper__Zh9uK {
  /* padding: 44px 20px 24px; */
  background: var(--background, #F9F9F9);
}

.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_Header__Ae5\\+G {
  position: sticky;
  top: 0;  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  height: 44px;
  background: var(--background, #F9F9F9);
}
.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_HeaderButton__C9ao\\+ {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}

.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_Title__qIaTk {
  color: var(--black);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px
}

.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_Section__Zh13E {
  margin-top: 18px;
}
.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_List__ENwZl {
  list-style: inside;
}
.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_Description__vIqW3 {

  margin-top: 16px;
  color: var(--black, #0F121B);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.PrivacyPolicy_BackArroowIcon__\\+Qw4h {
  width: 24px;
  height: 24px;
}

.PrivacyPolicy_PrivacyPolicy__OsMca .PrivacyPolicy_BackArroowIcon__\\+Qw4h path {
  color: var(--medium-gray);
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/PrivacyPolicy/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,6BAA6B;EAC7B,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,sCAAsC;AACxC;AACA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB;AACF;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;;EAEE,gBAAgB;EAChB,4BAA4B;EAC5B,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".PrivacyPolicy {\n  /* padding-bottom: 72px; */\n  min-height: 100vh;\n  background: var(--background, #F9F9F9);\n}\n\n.PrivacyPolicy .wrapper {\n  /* padding: 44px 20px 24px; */\n  background: var(--background, #F9F9F9);\n}\n\n.PrivacyPolicy .Header {\n  position: sticky;\n  top: 0;  \n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 8px;\n  height: 44px;\n  background: var(--background, #F9F9F9);\n}\n.PrivacyPolicy .HeaderButton {\n  width: 30%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.PrivacyPolicy .Title {\n  color: var(--black);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px\n}\n\n.PrivacyPolicy .Section {\n  margin-top: 18px;\n}\n.PrivacyPolicy .List {\n  list-style: inside;\n}\n.PrivacyPolicy .Description {\n\n  margin-top: 16px;\n  color: var(--black, #0F121B);\n  font-family: Open Sans;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 26px;\n}\n\n.BackArroowIcon {\n  width: 24px;\n  height: 24px;\n}\n\n.PrivacyPolicy .BackArroowIcon path {\n  color: var(--medium-gray);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrivacyPolicy": `PrivacyPolicy_PrivacyPolicy__OsMca`,
	"wrapper": `PrivacyPolicy_wrapper__Zh9uK`,
	"Header": `PrivacyPolicy_Header__Ae5+G`,
	"HeaderButton": `PrivacyPolicy_HeaderButton__C9ao+`,
	"Title": `PrivacyPolicy_Title__qIaTk`,
	"Section": `PrivacyPolicy_Section__Zh13E`,
	"List": `PrivacyPolicy_List__ENwZl`,
	"Description": `PrivacyPolicy_Description__vIqW3`,
	"BackArroowIcon": `PrivacyPolicy_BackArroowIcon__+Qw4h`
};
export default ___CSS_LOADER_EXPORT___;
