import React from 'react';
import { useParams } from 'react-router-dom';
import Main from '../../pages/Main';
import ThemePage from '../../pages/ThemePage';
import VideoPage from '../../pages/VideoPage';
// import UserLoader from '../../hoc/userLoader';
import ChapterPage from '../../pages/ChapterPage';
import SubthemePage from '../../pages/SubthemePage';
import Navbar from '../../ui/Navbar';
import { navItems } from '../../../utils/navItems';

export default function Content() {
  const { chapterId, themeId, subthemeId, videoId } = useParams();
  return (
    <>
      {/* <UserLoader> */}
        {
          chapterId
            ? themeId
              ? subthemeId
                ? videoId
                  ? <VideoPage />
                  : <SubthemePage />
                : <ThemePage />
              : <ChapterPage />
            : <Main />
        }
        <Navbar navItems={navItems}/>
      {/* </UserLoader> */}
    </>
  )
}
