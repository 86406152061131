import React, { useEffect, useState } from 'react';
import styles from './TasksPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { getTaskById, getTasks, getTasksDataStatus, loadImage, loadTasksList } from '../../../store/tasks';
import DifficultyBar from '../../ui/DifficultyBar';
import { ReactComponent as CheckSVG } from '../../../assets/completed-icon.svg';
import RadioField from '../../common/form/RadioField/RadioField';
import TextField from '../../common/form/TextField';
import CheckBoxField from '../../common/form/CheckBoxField';
import AnswerModal from '../../ui/AnswerModal';
import { addCompletedTask, deleteCompletedTask, getCompletedTasks } from '../../../store/user';

export default function TasksPage() {
  const dispatch = useDispatch();
  const { subthemeId, taskId } = useParams();
  // const { pathname } = useLocation();
  // const { state } = useLocation();
  // const obj = useLocation();
  const task = useSelector(getTaskById(taskId));
  const completedTasksList = useSelector(getCompletedTasks());
  const history = useHistory();
  const arrayTasks = useSelector(getTasks());
  const initialState = { input: "", radio: "", checkbox: [] };
  const [isValid, setIsValid] = useState(false);
  const [zoom, setZoom] = useState("");
  const [showCorrect, setShowCorrect] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [data, setData] = useState(initialState);
  const tasksDataStatus = useSelector(getTasksDataStatus());
  // const [data, setData] = useState({ answer: task?.type === "checkbox" ? [] : ""});
  function initializeStateCompletedTask(completed, task) {
    const initialState = completed?.some(v => v === task?.id);
    setCompleted(initialState);
  };
  
  useEffect(() => {
    if (!tasksDataStatus) dispatch(loadTasksList(subthemeId));
  }, [tasksDataStatus, subthemeId, dispatch]);

  useEffect(() => {
    initializeStateCompletedTask(completedTasksList, task);
  }, [task, completedTasksList]);

  const validate = (field) => {
    const isArray = Array.isArray(field);
    if (isArray) {
      if (field.length !== 0) setIsValid(true);
      setIsValid(false);
    }
    if (field) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  useEffect(() => {
    return () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  }, []);
  useEffect(() => {
    return () => {
      if (task) setData(initialState);
    };
  }, [task]);
  useEffect(() => {
    if (task?.type === "input") validate(data.input);
    if (task?.type === "radio") validate(data.radio);
    if (task?.type === "checkbox") validate(data.checkbox);
  }, [data.input, data.radio, data.checkbox, task?.type]);
  useEffect(() => {
    if (task?.pictures.length !== 0) {
      task?.pictures.forEach(pcts => dispatch(loadImage(pcts.slice(1))));
    }
  }, [task?.pictures, dispatch]);
  if (!task) {
    return;
  }
  const {options} = task;

  const goNextTask = () => {
    const index = arrayTasks.findIndex(tsk => task.id === tsk.id);
    let nextIndex;
    if (index === (arrayTasks.length - 1)) {
      nextIndex = 0;
    } else {
      nextIndex = index + 1;
    }
    const nextTask = arrayTasks.find((tsk, idx) => idx === nextIndex);
    if (!nextTask) return;
    history.push(`/tasks/${nextTask.parent}/${nextTask.id}`, {
      from: history.location.state.from
    });
  };

  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value
    }));
  };

  const handleChangeCheckBox = (target) => {
    const copyAnswer = [...data.checkbox];
    if (data.checkbox.includes(target.value)) {
      const filter = copyAnswer.filter(el => el !== target.value);
      setData((prevState) => ({
        ...prevState,
        checkbox: filter
      }));
    } else {
      copyAnswer.push(target.value);
      setData((prevState) => ({
        ...prevState,
        checkbox: copyAnswer
      }));
    }
  };

  const checkCorrectAnswers = () => {
    if (task.type === "checkbox") {
      if (data.checkbox.length !== task.answers.length) return false;
      for (const item of data.checkbox) {
        const isExist = task.answers.includes(item);
        if (!isExist) return false;
      }
      return true;
    }
    if (task.type === "input") {
      return data.input.toLowerCase() === task.answers[0].toLowerCase();
    }
    return data.radio.toLowerCase() === task.answers[0].toLowerCase();
  };

  return (
    <div className={styles.TasksPage} >
      <div className={styles.Header}>
        <div
          className={styles.HeaderButton}
          onClick={() => history.push(history.location.state.from)}
        >
          <BackArroowIcon className={styles.BackArroowIcon} />
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.wrap}>
          <label className={styles.Title}>№{task.order}</label>
          {completed && <div className={styles.isWatched}>
            <CheckSVG />
            РЕШЕНА
          </div>}
        </div>
        <DifficultyBar difficulty={task.difficulty} style={{marginBottom: "24px"}}/>
        <div className={styles.Description}>
          {task.question}
        </div>
        {task.pictures.map((picture, idx) => (
          <div
            key={idx}
            className={`${styles.Image} ${zoom === idx ? styles.zoom : ""}`}
            onClick={() => setZoom(zoom === idx ? "" : idx)}
          >
            <img src={`https://api.knowledgetree.getaclass.ru${picture}`} alt={`Картинка #${idx + 1} для задачи №${task.order}`} loading="eager"/>
          </div>
        ))
        }
        {options.length !== 0 && <ul className={styles.Options}>
        {task.type === "radio" && options.map(option => (
          <li
            key={option.value}
            className={styles.Element}
          >
            <RadioField
              value={data.radio}
              onChange={handleChange}
              option={option}
              name="radio"
              style={{ marginBottom: "0" }}
              onCancel={()=>{}}
            />
          </li>
        ))}
        {task.type === "checkbox" && options.map(option => (
          <li
            key={option.value}
            className={styles.Element}
          >
            <CheckBoxField
              value={data.checkbox}
              onChange={handleChangeCheckBox}
              option={option}
              name="checkbox"
              style={{ marginBottom: "0" }}
            />
          </li>
        ))}
        </ul>}
      </div>
      <div className={styles.wrapper}>
        {task.type === "input" && 
          <TextField
            placeholder="Введите ответ"
            type="text"
            name="input"
            value={data.input}
            onChange={handleChange}
            style={{ marginBottom: "12px" }}
          />
        }
        <button
          className={styles.PrimaryBtn}
          disabled={!isValid}
          onClick={(e) => {
            e.preventDefault();
            if (checkCorrectAnswers()) {
              setShowCorrect(true);
              dispatch(addCompletedTask({id: task.id}));
            } else {
              setShowWrong(true);
              // dispatch(deleteCompletedTask({id: task.id}));
            }
          }}
        >Ответить</button>
      </div>
        <button
          className={styles.SecondaryBtn}
          onClick={(e) => {
            e.preventDefault();
            goNextTask();
          }}
        >Перейти к следующей</button>
        {showCorrect &&
          <AnswerModal
            open={showCorrect}
            onClose={() => setShowCorrect(false)}
            label="Правильно!"
            labelOk="Следующая задача"
            labelCancel="Назад"
            text="Вы дали правильный ответ"
            onConfirm={goNextTask}
            onCancel={()=>{}}
            labelStyle={{color: "#22bb33"}}
          />
        }
        {showWrong &&
          <AnswerModal
            open={showWrong}
            onClose={() => setShowWrong(false)}
            label="Неправильный ответ"
            labelStyle={{color: "#ED1C24"}}
            labelOk="Вернуться к задаче"
            labelCancel="Следующая задача"
            text="Попробуйте решить задачу ещё раз"
            onConfirm={()=>{}}
            onCancel={goNextTask}
          />
        }
    </div>
  )
}
