import React from 'react';
import styles from './SummaryPage.module.css';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { useSelector } from 'react-redux';
import { getVideoById } from '../../../store/videos';
import ViewerPDF from '../../common/pdf/ViewerPDF';
import PDFViewer from '../../common/pdf/PDFViewer';

export default function SummaryPage() {
  const history = useHistory();
  const { chapterId, themeId, subthemeId, videoId } = useParams();
  const video = useSelector(getVideoById(videoId));
  if (video) {
    return (
      <div className={styles.SummaryPage} >
        <div className={styles.wrapper} >
          <div className={styles.Header}>
            <div
              onClick={() => history.goBack()}
              className={styles.BackArroowIcon}
            >
              <BackArroowIcon />
            </div>
            <div className={styles.Title}>Конспект</div>
            <div style={{ width: '20px' }}></div>
          </div>
          {/* <iframe src={`https://api.knowledgetree.getaclass.ru/Summary/${videoId}`} >
          </iframe> */}
          {/* <ViewerPDF
            pdf={`https://api.knowledgetree.getaclass.ru/Summary/${videoId}`}
            // document={`Конспект по ${video.title}`}
            // videoId={videoId}
          /> */}
          <PDFViewer
            urlPDF={`https://api.knowledgetree.getaclass.ru/Summary/${videoId}`}
            // document={`Конспект по ${video.title}`}
            // videoId={videoId}
          />
        </div>
      </div>
    )
  }
}
