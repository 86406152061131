// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewerPDF_ViewerPDF__\\+l-xb {
  width: 100%;
  height: 100vh;
}
.ViewerPDF_ViewerPDF__\\+l-xb .ViewerPDF_Document__IXBQj {
  width: 100% !important;
  height: 100vh !important;
  /* --scale-factor: 0 !important; */
}
.ViewerPDF_ViewerPDF__\\+l-xb .ViewerPDF_Page__e4zLV {
  width: 100% !important;
  height: auto !important;
  /* --scale-factor: 0 !important; */
}
.ViewerPDF_ViewerPDF__\\+l-xb .ViewerPDF_Page__e4zLV canvas{
  width: 100% !important;
  height: auto !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/pdf/ViewerPDF/ViewerPDF.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,kCAAkC;AACpC;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kCAAkC;AACpC;AACA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".ViewerPDF {\n  width: 100%;\n  height: 100vh;\n}\n.ViewerPDF .Document {\n  width: 100% !important;\n  height: 100vh !important;\n  /* --scale-factor: 0 !important; */\n}\n.ViewerPDF .Page {\n  width: 100% !important;\n  height: auto !important;\n  /* --scale-factor: 0 !important; */\n}\n.ViewerPDF .Page canvas{\n  width: 100% !important;\n  height: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ViewerPDF": `ViewerPDF_ViewerPDF__+l-xb`,
	"Document": `ViewerPDF_Document__IXBQj`,
	"Page": `ViewerPDF_Page__e4zLV`
};
export default ___CSS_LOADER_EXPORT___;
