import axios from "axios";
import configFile from "../config.json";
import authService from "./auth.service";
import localStorageService from "./localStorage.service";

const http = axios.create({
  baseURL: configFile.apiEndpoint
});

http.interceptors.request.use(
  async function (config) {
    const expiresAccessDate = localStorageService.getExpiresAccessDate();
    // const expiresRefreshDate = localStorageService.getExpiresRefreshDate();
    const refreshToken = localStorageService.getRefreshToken();
    const isExpired = refreshToken && expiresAccessDate < Date.now();
    const accessToken = localStorageService.getAccessToken();
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`
      };
    }

    if (isExpired) {
      const data = await authService.refresh(refreshToken);
      localStorageService.setTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        expireAccess: data.accessTokenExpiresIn,
        expireRefresh: data.refreshTokenExpiresIn
      });
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (res) => {
    res.data = { content: res.data };
    return res;
  },
  async function (error) {
    // const originalConfig = error.config;
    // const checkUrlAuthPath = (url) => {
    //   const registerPath = "/login/register";
    //   const loginPath = "/login/login";
    //   return ((url !== registerPath) && (url !== loginPath));
    // };
    // if (checkUrlAuthPath(originalConfig.url) && error.response) {
    //   if (error.response.status === 401 && !originalConfig._retry) {
    //     originalConfig._retry = true;
    //     const refreshToken = localStorageService.getRefreshToken();
    //     const data = await authService.refresh(refreshToken);
    //     localStorageService.setTokens({
    //       accessToken: data.accessToken,
    //       refreshToken: data.refreshToken,
    //       expireAccess: data.accessTokenExpiresIn,
    //       expireRefresh: data.refreshTokenExpiresIn
    //     });
    //   }
    // }
    return Promise.reject(error);
  }
);

const httpService = {
  get: http.get,
  post: http.post,
  put: http.put,
  patch: http.patch,
  delete: http.delete
};
export default httpService;
