import httpService from "./http.service";
// import localStorageService from "./localStorage.service";

const themeEndpoint = "Themes/";

const themeService = {
  fetchAll: async () => {
    const { data } = await httpService.get(themeEndpoint);
    return data;
  }
};

export default themeService;
