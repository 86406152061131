export const profileItems = [
  { path: "/profile/userinfo", label: "Личная информация" },
  { path: "/profile/changepassword", label: "Сменить пароль" },
  { path: "/onboarding", label: "О приложении" },
  { path: "/login/privacy", label: "Обработка данных" },
  { path: "/", label: "Выйти" },
  { path: "/", label: "Удалить аккаунт" }
];
export const securityItems = [
  { path: "/profile/security/changepassword", label: "Сменить пароль" }
];