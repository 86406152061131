import React, { useEffect, useState } from 'react';
import { navItems } from '../../../utils/navItems';

import styles from './Favorites.module.css';
import logo from '../../../assets/Logo.webp';
import Header from '../../ui/Header/Header';
import Navbar from '../../ui/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoritesVideos, getIsLoggedIn, loadUserProfile } from '../../../store/user';
import ItemsList from '../../ui/ItemsList';
import { getVideos } from '../../../store/videos';

export default function Favorites() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const isLoggedIn = useSelector(getIsLoggedIn());
  const favoritesVideos = useSelector(getFavoritesVideos());
  const allVideos = useSelector(getVideos());
  const videos = allVideos?.filter(video => favoritesVideos.includes(video?.id));
  
  useEffect(()=>{
    if (isLoggedIn) dispatch(loadUserProfile());
  },[isLoggedIn, dispatch]);

  useEffect(() => {
    if (videos) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 700);
      return () => {
        clearTimeout(timeout);
        // setLoading(true);
      };
    }
  }, [videos]);

  const renderEmptyText = () => {
    return (
      <div className={styles.Text}>В этом разделе будут отображаться видеоролики, которые вы отметили как избранные{' '}
        <div style={{ color: 'red', display: 'inline-block' }}>&#10084;</div>
      </div>
    );
  };
  return (
    <>
      <div className={styles.Favorites}>
        <Header label="Избранное" logo={logo} />
          <div className={styles.Wrapper}>
            { !isLoading
                ? ((favoritesVideos.length === 0) || (!videos))
                  ?  renderEmptyText()
                  : <ItemsList items={videos} type='videos' />
                : <ItemsList items={[1, 2]} type='skeletons' />
            }
        </div>
      </div>
      <Navbar navItems={navItems} />
    </>
  )
}
