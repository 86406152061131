// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkeletonCard_SkeletonCard__kbaIZ {
  background: var(--input-background, #eee);
  width: 100%;
  margin-bottom: 16px;
  border-radius: 12px;
  min-height: 286px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ui/SkeletonCard/SkeletonCard.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".SkeletonCard {\n  background: var(--input-background, #eee);\n  width: 100%;\n  margin-bottom: 16px;\n  border-radius: 12px;\n  min-height: 286px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SkeletonCard": `SkeletonCard_SkeletonCard__kbaIZ`
};
export default ___CSS_LOADER_EXPORT___;
