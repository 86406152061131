import React from 'react';
import styles from './ChapterPage.module.css';
import ItemsList from '../../ui/ItemsList';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { getChapterById } from '../../../store/chapters';
import { getThemes } from '../../../store/themes';
import { sortItems } from '../../../utils/sortItems';

export default function ChapterPage() {
  const { chapterId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const chapter = useSelector(getChapterById((chapterId)));
  const themes = useSelector(getThemes())?.filter(el => el.chapterId === Number(chapterId));
  const state = history?.location?.state?.from ?? null;
  const goBack = () => {
    if (state) {
      history.push(state);
    } else {
      history.push(`/video`);
    }
  };
  
  const sortedThemes = sortItems(themes);

  return (
    <div className={styles.ChapterPage} >
      <div className={styles.Header}>
        <div
          className={styles.HeaderButton}
          onClick={goBack}
        >
          <BackArroowIcon className={styles.BackArroowIcon} />
        </div>
        <div
          className={styles.HeaderButton}
          onClick={() => history.push(`${pathname}/info`)}
        >
          <InfoIcon className={styles.InfoIcon} />
        </div>
      </div>
      <div className={styles.wrapper} >
        {chapter && <h2 className={styles.Title}>{chapter.title}</h2>}
      </div>
      <div className={styles.List} >
        {sortedThemes ? <ItemsList items={sortedThemes} type="themes" /> : <ItemsList items={[1, 2, 3]} type='skeletons' styleCard={{ minHeight: "158px" }}/>}
      </div>
    </div>
  )
}
