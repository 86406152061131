import httpService from "./http.service";
// import localStorageService from "./localStorage.service";

const chapterEndpoint = "Chapters/";

const chapterService = {
  fetchAll: async () => {
    const { data } = await httpService.get(chapterEndpoint);
    return data;
  }
};

export default chapterService;
