// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_Login__erTPg {
  padding: 0px 20px 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: var(--background, #f9f9f9);
}

.Login_Login__erTPg .Login_Header__xvEV8 {
  color: var(--black, #0f121b);
  font-family: 'Open Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.Login_Login__erTPg .Login_Greeting__K9U83 {
  color: var(--black-80, rgba(15, 18, 27, 0.80));
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 40px;
}

.Login_Login__erTPg .Login_Greeting__K9U83>a {
  color: var(--error, #ED1C24);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/layouts/Login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,iBAAiB;EACjB,4CAA4C;AAC9C;;AAEA;EACE,4BAA4B;EAC5B,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,8CAA8C;EAC9C,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".Login {\n  padding: 0px 20px 44px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  min-height: 100vh;\n  background-color: var(--background, #f9f9f9);\n}\n\n.Login .Header {\n  color: var(--black, #0f121b);\n  font-family: 'Open Sans';\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px;\n  margin-bottom: 16px;\n}\n\n.Login .Greeting {\n  color: var(--black-80, rgba(15, 18, 27, 0.80));\n  font-family: 'Open Sans';\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n  margin-bottom: 40px;\n}\n\n.Login .Greeting>a {\n  color: var(--error, #ED1C24);\n  font-family: 'Open Sans';\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Login": `Login_Login__erTPg`,
	"Header": `Login_Header__xvEV8`,
	"Greeting": `Login_Greeting__K9U83`
};
export default ___CSS_LOADER_EXPORT___;
