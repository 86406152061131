import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilterStatusApp, getFilterStatusApp } from '../../../store/user';
import styles from './FilterBar.module.css';

export default function FilterBar({ items }) {
  const dispatch = useDispatch();
  const initialState = useSelector(getFilterStatusApp());
  const [active, setActive] = useState(initialState ?? items[0].value);
  const handleActiveItem = (value) => {
    setActive(value);
    dispatch(changeFilterStatusApp(value));
  };
  return (
    <div className={styles.FilterBar}>
      <ul>
        {items.map(item => (
          <li key={item.id}>
            <button
              onClick={() => handleActiveItem(item.value)}
              className={`${styles.FilterButton} ${active === item.value ? styles.active : ""}`}
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
