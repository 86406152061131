function generateAuthError(message) {
  switch (message) {
    case "Password is wrong":
      return "Неправильный пароль";
    case "PasswordMismatch":
      return "Неправильный email или пароль";
    case "DuplicateUserName":
      return "Этот email уже зарегистрирован";
    case "DuplicateEmail":
      return "Этот email уже зарегистрирован";
    case "PasswordRequiresUniqueChars":
      return "Пароль должен содержать уникальные символы";
    case "UserNotFound":
      return "Пользователь не найден";
    default:
      return "Слишком много попыток входа. Попробуйте войти позже";
  }
};
export default generateAuthError;
