import React, { useEffect, useState } from 'react';
import styles from './Search.module.css';
import { navItems } from '../../../utils/navItems';
import Navbar from '../../ui/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDictionaryTags, getDictionaryTagsDataStatus, loadTagsList } from '../../../store/dictionaries';
import SearchField from '../../common/form/SearchField';
import { getVideos } from '../../../store/videos';
import ItemsList from '../../ui/ItemsList';
import { getChapters } from '../../../store/chapters';
import { getThemes } from '../../../store/themes';
import { getSubThemes } from '../../../store/subthemes';

export default function Search() {
  const dispatch = useDispatch();
  const tagsDataStatus = useSelector(getDictionaryTagsDataStatus());
  const [searchValue, setSearchValue] = useState("");
  const [showResult, setShowResult] = useState(false);
  const tags = useSelector(getDictionaryTags());
  const videos = useSelector(getVideos())?.filter(video => !!video.subThemeId);
  const chapters = useSelector(getChapters());
  const themes = useSelector(getThemes());
  const subthemes = useSelector(getSubThemes());
  useEffect(() => {
    if (!tagsDataStatus) dispatch(loadTagsList());
  }, [tagsDataStatus, dispatch]);

  const handleSearchChange = ({ target }) => {
    const { value } = target;
    setSearchValue(value);
  };

  const handleCleanFilter = () => {
    setSearchValue("");
    setShowResult(false);
  };

  function filterThemes(chapters, themes, subthemes) {
    const filteredChapters = searchValue
      ? chapters.filter(
        (el) =>
          (el
            .title
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
          )
      )
      : chapters;
    const filteredThemes = searchValue
      ? themes.filter(
        (el) =>
          (el
            .title
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
          )
      )
      : themes;
    const filteredSubThemes = searchValue
      ? subthemes.filter(
        (el) =>
          (el
            .title
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
          )
      )
      : subthemes;
    const total =  (filteredChapters?.length + filteredThemes?.length + filteredSubThemes?.length) ?? 0;
    return {chapters: filteredChapters, themes: filteredThemes, subthemes: filteredSubThemes, length: total};
  };
  function filterVideos(data) {
    const filteredVideo = searchValue
      ? data.filter(
        (el) =>
          (el
            .title
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
          ) || (
            el?.tags?.includes(searchValue)
          )
      )
      : data;
    return filteredVideo;
  };
  function filterOptions(data) {
    if (!data) return;
    const filteredTags = searchValue
      ? data.filter(
        (tag) =>
          tag
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
      )
      : data;
    return filteredTags;
  };

  const getUniqueArrayOfObjects = (array, key) => {
    return [...new Map(array.map(item => [item[key], item])).values()];
  };

  const filteredTags = filterOptions(tags);
  const filteredVideos = filterVideos(videos);
  const filteredThemes = filterThemes(chapters, themes, subthemes);
  const uniqueVideos = getUniqueArrayOfObjects(filteredVideos, "title");
  return (
    <>
      <div className={styles.Search}>
        <div className={styles.Header}>
          <SearchField
            placeholder="Поиск"
            name="search"
            labelAction={!showResult ? "Поиск" : "Назад"}
            value={searchValue}
            onChange={handleSearchChange}
            onCleanField={handleCleanFilter}
            onGoAction={() => {
              if (searchValue) {
                setShowResult(prev => !prev)
              }
            }}
          />
        </div>
        {searchValue && !showResult && <div className={styles.BanerResult}>
          {filteredTags?.length === 0 ? "ТЕГ НЕ НАЙДЕН" : `РЕЗУЛЬТАТОВ ${filteredTags?.length}`}
        </div>}
        {!showResult && <div className={styles.TagList}>
          {tags && filteredTags.map((tag, idx) => (
            <span
              key={idx}
              className={styles.TagElement}
              onClick={() => {
                setSearchValue(tag);
                setShowResult(true);
              }}
            >
              {tag}
            </span>
          ))}
        </div>}
        {searchValue && showResult && <div className={styles.BanerResult}>
          {
            filteredVideos?.length === 0
              ?  filteredThemes?.length === 0
                ? "НИЧЕГО НЕ НАЙДЕНО"
                : `ТЕМЫ ${filteredThemes?.length}`
              : `УРОКИ ${uniqueVideos?.length}`
          }
        </div>}
        {uniqueVideos.length !== 0 && showResult && <ItemsList type='mini' items={uniqueVideos} />}
        {searchValue && showResult && filteredThemes.length !== 0 && filteredVideos.length !== 0 && <div className={styles.BanerResult}>
          {`ТЕМЫ ${filteredThemes.length}`}
        </div>}
        {filteredThemes.chapters.length !== 0 && showResult && <ItemsList style={{ paddingTop: "0px" }} items={filteredThemes.chapters} special />}
        {filteredThemes.themes.length !== 0 && showResult && <ItemsList type='themes' style={{ paddingTop: "0px" }} items={filteredThemes.themes} special />}
        {filteredThemes.subthemes.length !== 0 && showResult && <ItemsList type='subthemes' style={{ paddingTop: "0px" }} items={filteredThemes.subthemes} special />}
      </div>
      <Navbar navItems={navItems} />
    </>
  )
}
