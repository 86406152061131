// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DifficultyBar_DifficultyBar__8AeYV {
  background: inherit;
  width: 100%;
  display: flex;
}
.DifficultyBar_DifficultyBar__8AeYV .DifficultyBar_Indicator__D9O7X {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DifficultyBar_DifficultyBar__8AeYV .DifficultyBar_circle__AB1pM {
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--black-16, rgba(15, 18, 27, 0.16));
}
.DifficultyBar_DifficultyBar__8AeYV .DifficultyBar_circle__AB1pM.DifficultyBar_red__GdD0L {
  background: var(--error, #ED1C24);
}
.DifficultyBar_DifficultyBar__8AeYV .DifficultyBar_Description__8OQY3 {
  color: var(--medium-gray, #808080);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-left: 4px;
}



`, "",{"version":3,"sources":["webpack://./src/app/components/ui/DifficultyBar/DifficultyBar.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mDAAmD;AACrD;AACA;EACE,iCAAiC;AACnC;AACA;EACE,kCAAkC;EAClC,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".DifficultyBar {\n  background: inherit;\n  width: 100%;\n  display: flex;\n}\n.DifficultyBar .Indicator {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.DifficultyBar .circle {\n  margin-right: 6px;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background: var(--black-16, rgba(15, 18, 27, 0.16));\n}\n.DifficultyBar .circle.red {\n  background: var(--error, #ED1C24);\n}\n.DifficultyBar .Description {\n  color: var(--medium-gray, #808080);\n  font-family: 'Open Sans';\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n  margin-left: 4px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DifficultyBar": `DifficultyBar_DifficultyBar__8AeYV`,
	"Indicator": `DifficultyBar_Indicator__D9O7X`,
	"circle": `DifficultyBar_circle__AB1pM`,
	"red": `DifficultyBar_red__GdD0L`,
	"Description": `DifficultyBar_Description__8OQY3`
};
export default ___CSS_LOADER_EXPORT___;
