import React from 'react';
import styles from './PrivacyPolicy.module.css';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';

export default function PrivacyPolicy({ item, onChangeContent }) {
  const history = useHistory();
  const state = history?.location?.state?.from ?? null;

  const goBackHandler = (value) => {
    if (state) {
      history.push(state);
    } else {
      onChangeContent(value);
      history.push(`${item.backPath}`);
    }
  };
  const renderElements = () => {
    return item?.elements.map(el => (
      <section key={el?.id} className={styles.Section}>
        <h3>{el?.title}</h3>
        <ul className={styles.List}>
          { renderPoints(el?.points) }
        </ul>
      </section>
    ))
  };
  const renderPoints = (points) => {
    return points.map((point, index) => (<li key={index} className={styles.Description}>{point}</li>))
  };
  return (
    <div className={styles.PrivacyPolicy} >
      <div className={styles.wrapper} >
        <div className={styles.Header}>
          <div
            className={styles.HeaderButton}
            onClick={() => goBackHandler('register')}
          >
            <BackArroowIcon className={styles.BackArroowIcon} />
          </div>
        </div>
        <h2 className={styles.Title}>{item?.name}</h2>
        <article className={styles.Description}>{item?.description}</article>
        {renderElements()}
      </div>
    </div>
  )
}
