import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsLoggedIn } from "../../store/user";
import UserLoader from "./userLoader";

const ProtectedRoute = ({ component: Component, children, ...rest }) => {
  const isLoggedIn = useSelector(getIsLoggedIn());

  return (<Route {...rest} render={(props) => {
    if (!isLoggedIn) {
      return <Redirect to={{
        pathname: "/login/login",
        state: {
          from: props.location
        }
      }} />;
    } else {
      return (
        <UserLoader>
          {Component ? <Component {...props} /> : children}
        </UserLoader>
      )
    }
  }} />);
};

export default ProtectedRoute;
