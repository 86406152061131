import React, { useEffect, useState } from 'react';
import styles from './Main.module.css';
import logo from '../../../assets/Logo.webp';
import qr from '../../../assets/QR.webp';
import Header from '../../ui/Header/Header';
import ItemsList from '../../ui/ItemsList';
import { useDispatch, useSelector } from 'react-redux';
import FilterBar from '../../ui/FilterBar';
import { filterItems } from '../../../utils/filterItems';
import { sortItems } from '../../../utils/sortItems';
import ItemCard from '../../ui/ItemCard';
import Navbar from '../../ui/Navbar';
import { navItems } from '../../../utils/navItems';
import { getFilterStatusApp, getUserProfile } from '../../../store/user';
import { getThemes } from '../../../store/themes';
import { getSubThemes, getSubThemeStateDisplay, toggleDisplayList } from '../../../store/subthemes';
import { getChapters } from '../../../store/chapters';
import { getVideos } from '../../../store/videos';
import { getTasks, getTasksDataStatus, loadTasksByClassList } from '../../../store/tasks';
import useMediaQuery from "../../../hooks/useMediaQuery";
import PopUp from '../../ui/PopUp';

function useMediaQueries() {
  const mobile = useMediaQuery("(max-width: 1025px)");

  return { mobile };
}

export default function Main() {
  const dispatch = useDispatch();
  const [showQR, setShowQR] = useState(false);
  const { mobile } = useMediaQueries();
  const chapters = useSelector(getChapters());
  const themes = useSelector(getThemes());
  const videos = useSelector(getVideos());
  const currentUser = useSelector(getUserProfile());
  const filterApp = useSelector(getFilterStatusApp());
  const active = useSelector(getSubThemeStateDisplay());
  const tasks = useSelector(getTasks());
  const sortedChapters = sortItems(chapters);
  
  useEffect(() => {
    if (!mobile) {
      setShowQR(true);
    } else {
      setShowQR(false);
    }
  }, [mobile]);

  useEffect(() => {
    if (filterApp !=='all') dispatch(loadTasksByClassList(filterApp));
  }, [filterApp, dispatch]);

  const renderMainListItems = (items) => {
    return items.map(item => {
      const children = themes.filter(theme => theme?.chapterId === item?.id);
      const sortedChildren = sortItems(children);
      return (
        <div key={item.id}>
          <ItemCard item={item} />
          <ItemsList items={sortedChildren} type="themes" style={{ paddingTop: "0px" }} />
        </div>
      );
    })
  };
  const getUniqueArrayOfObjects = (array, key) => {
    return [...new Map(array.map(item => [item[key], item])).values()];
  };
  const renderVideoList = (videos, filter) => {
    const filtredVideos = videos.filter(video => video?.classGrade.includes(filter));
    const unique = getUniqueArrayOfObjects(filtredVideos, "title");
      return (
        <ItemsList items={unique} type='videos' />
      );
  };
  const renderTasksList = (tasks) => {
    // const filtredVideos = videos.filter(video => video?.classGrade.includes(filter));
    // const unique = getUniqueArrayOfObjects(filtredVideos, "title");
      return (
        <ItemsList items={tasks} type='tasks' />
      );
  };

  return (
    <>
      <div className={styles.Main}>
        {/* <Header label={`Привет ${currentUser?.firstName ? currentUser?.firstName : ""}!`} logo={logo} /> */}
        <Header label={``} logo={logo} />
        <FilterBar items={filterItems} />
        {filterApp !== "all" && <div className={styles.Panel} >
          <div className={styles.Background}>
            <button
              className={`${styles.Btn} ${active ? styles.active : ""}`}
              onClick={() => dispatch(toggleDisplayList(true))}
            >
              Уроки
            </button>
            <button
              className={`${styles.Btn} ${!active ? styles.active : ""}`}
              onClick={() => {
                dispatch(toggleDisplayList(false));
                // dispatch(loadTasksByClassList(filterApp));
              }}
            >
              Задачи
            </button>
          </div>
        </div>}
        <div className={styles.wrapper}>
          {filterApp === 'all'
            ? (themes && sortedChapters)
              ? renderMainListItems(sortedChapters)
              : <ItemsList items={[1, 2, 3]} type='skeletons' styleCard={{ minHeight: "158px" }}/>
            : active
              ? (videos && filterApp) ? renderVideoList(videos, filterApp) : <ItemsList items={[1, 2]} type='skeletons' />
              : (tasks && filterApp) ? renderTasksList(tasks) : <ItemsList items={[1, 2, 3]} type='skeletons' styleCard={{ minHeight: "174px" }}/>
          }
        </div>
        {showQR &&
          <PopUp
            open={showQR}
            content={qr}
            alt="QR-код сайта mobile.getaclass.ru"
            text="Приложение доступно для скачивания в RuStore, скоро появится в Google play и Apple Store. А также можно перейти по QR-коду."
            // label="Заголовок"
            onClose={() => setShowQR(false)}
          />
        }
      </div>
    </>
  )
}
