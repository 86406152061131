// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SummaryPage_SummaryPage__FBSr9 {
  padding-bottom: 72px;
  min-height: 100vh;
  background: var(--background, #F9F9F9);
}

.SummaryPage_SummaryPage__FBSr9 .SummaryPage_wrapper__3cJKT {
  padding: 0px 20px 24px;
  background: var(--background, #F9F9F9);
  height: 100vh;
  z-index: 2;
}

.SummaryPage_SummaryPage__FBSr9 .SummaryPage_Header__wxnID {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: var(--background, #F9F9F9);
}
.SummaryPage_SummaryPage__FBSr9 .SummaryPage_HeaderButton__KhEpt {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}

.SummaryPage_SummaryPage__FBSr9 .SummaryPage_Title__l5u6i {
  color: var(--black, #0F121B);
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.SummaryPage_SummaryPage__FBSr9 .SummaryPage_BackArroowIcon__Ag5Gu {
  width: 24px;
  height: 24px;
}

.SummaryPage_SummaryPage__FBSr9 .SummaryPage_BackArroowIcon__Ag5Gu path {
  color: var(--medium-gray);
}
.SummaryPage_SummaryPage__FBSr9 iframe {
  width: 100%;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/SummaryPage/SummaryPage.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,sCAAsC;EACtC,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,WAAW;EACX,sCAAsC;AACxC;AACA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":[".SummaryPage {\n  padding-bottom: 72px;\n  min-height: 100vh;\n  background: var(--background, #F9F9F9);\n}\n\n.SummaryPage .wrapper {\n  padding: 0px 20px 24px;\n  background: var(--background, #F9F9F9);\n  height: 100vh;\n  z-index: 2;\n}\n\n.SummaryPage .Header {\n  height: 44px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n  position: sticky;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 10;\n  background: var(--background, #F9F9F9);\n}\n.SummaryPage .HeaderButton {\n  width: 30%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.SummaryPage .Title {\n  color: var(--black, #0F121B);\n  text-align: center;\n  font-family: 'Open Sans';\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 26px;\n}\n\n.SummaryPage .BackArroowIcon {\n  width: 24px;\n  height: 24px;\n}\n\n.SummaryPage .BackArroowIcon path {\n  color: var(--medium-gray);\n}\n.SummaryPage iframe {\n  width: 100%;\n  height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SummaryPage": `SummaryPage_SummaryPage__FBSr9`,
	"wrapper": `SummaryPage_wrapper__3cJKT`,
	"Header": `SummaryPage_Header__wxnID`,
	"HeaderButton": `SummaryPage_HeaderButton__KhEpt`,
	"Title": `SummaryPage_Title__l5u6i`,
	"BackArroowIcon": `SummaryPage_BackArroowIcon__Ag5Gu`
};
export default ___CSS_LOADER_EXPORT___;
