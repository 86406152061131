import React from 'react';
import styles from './Header.module.css';

export default function Header({ label, logo }) {
  return (
    <div className={styles.Header}>
      <h2 className={styles.label}>{label}</h2>
      <div className={styles.logo}>
        <img src={logo} alt='Логотип getaclass'></img>
      </div>
    </div>
  )
}
