import React, { useState } from "react";
import styles from "./OptionsList.module.css";
import ReactDom from 'react-dom';
import { useHistory } from "react-router-dom";
import { ReactComponent as BackArroowIcon } from '../../../../assets/back-arrow.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/search-icon.svg';
import RadioField from "../RadioField/RadioField";
import SearchField from "../SearchField/SearchField";

function OptionsList({ name, value, onChange, options, style, isOpen, onClose, label }) {
  const history = useHistory();
  const [isShowSearchField, setShowSearchField] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleChange = (target) => {
    onChange({ name: target.name, value: target.value });
  };
  const selectOption = (option) => {
    if (option !== value) handleChange(option);
  };
  const isOptionSelected = (option) => {
    return option === value;
  };
  const handleSearchChange = ({ target }) => {
    const { value } = target;
    setSearchValue(value);
  };

  const handleCleanFilter = () => {
    setSearchValue("");
  };

  function filterOptions(data) {
    const filteredOptions = searchValue
      ? data.filter(
        (option) =>
          option.label
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
      )
      : data;
    return filteredOptions;
  }
  const filteredOptions = filterOptions(options);
  return ReactDom.createPortal(
    <div
      className={styles.OptionsList}
      tabIndex={0}
      style={style}
      onClick={e =>
        e.stopPropagation()
      }
    >
      <div className={styles.Header}>
        {!isShowSearchField && <div className={styles.HeaderWrap}>
          <div
            className={styles.BackArroowIcon}
            onClick={() => onClose(false)}
          >
            <BackArroowIcon />
          </div>
          <div className={styles.Title}>{label}</div>
          <div
            onClick={() => setShowSearchField(prev => !prev)}
            className={styles.SearchIcon}
          >
            <SearchIcon />
          </div>
        </div>}
        {isShowSearchField &&
          <SearchField
            placeholder="Поиск"
            name="search"
            labelButton="Назад"
            value={searchValue}
            onChange={handleSearchChange}
            onCleanField={handleCleanFilter}
            onGoBack={() => setShowSearchField(false)}
          />
        }
      </div>
      {searchValue && <div className={styles.BanerResult}>
        {filteredOptions.length === 0 ? "НИЧЕГО НЕ НАЙДЕНО" : `РЕЗУЛЬТАТОВ ${filteredOptions.length}`}
      </div>}
      <ul className={styles.Options}>
        {options && filteredOptions.map(option => (
          <li key={option.value}>
            <RadioField
              value={value}
              onChange={onChange}
              option={option}
              name={name}
              onCancel={() => onClose(false)}
            />
          </li>
        ))}
      </ul>
    </div>,
    document.getElementById("popup")
  );
}

export default OptionsList;
