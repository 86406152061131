import { createSlice } from "@reduxjs/toolkit";
import dictionaryService from "../services/dictionary.service";
import { transformCountries } from "../utils/transformCountries";

const dictionariesSlice = createSlice({
  name: "dictionaries",
  initialState: {
    tags: null,
    // tags: null,
    tagsIsLoading: true,
    tagsDataLoaded: false,
    // countries: transformCountries(countries, "Россия"),
    countries: null,
    currentCountry: null,
    countriesIsLoading: true,
    countriessDataLoaded: false,
    // cities: transformCountries(cities, "Новосибирск"),
    cities: null,
    citiesIsLoading: true,
    citiesDataLoaded: false,
    error: null,
  },
  reducers: {
    tagsRequested: (state) => {
      state.tagsIsLoading = true;
    },
    tagsReceived: (state, action) => {
      state.tags = action.payload;
      state.tagsDataLoaded = true;
      state.tagsIsLoading = false;
    },
    tagsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.tagsIsLoading = false;
    },
    countriesRequested: (state) => {
      state.countriesIsLoading = true;
    },
    countriesReceived: (state, action) => {
      state.countries = action.payload;
      state.countriessDataLoaded = true;
      state.countriesIsLoading = false;
    },
    countriesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.countriesIsLoading = false;
    },
    citiesRequested: (state) => {
      state.citiesIsLoading = true;
    },
    citiesReceived: (state, action) => {
      state.cities = action.payload;
      state.citiesDataLoaded = true;
      state.citiesIsLoading = false;
    },
    citiesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.citiesIsLoading = false;
    },
    currentCountrySeted: (state, action) => {
      state.currentCountry = action.payload;
    }
  }
});

const { actions, reducer: dictionariesReducer } = dictionariesSlice;
const {
  tagsRequested,
  tagsReceived,
  tagsRequestFailed,
  citiesReceived,
  citiesRequested,
  citiesRequestFailed,
  countriesRequested,
  countriesReceived,
  countriesRequestFailed,
  currentCountrySeted
} = actions;

export const loadTagsList = () => async (dispatch) => {
  dispatch(tagsRequested());
  try {
    const { content } = await dictionaryService.getTags();
    dispatch(tagsReceived(content));
  } catch (error) {
    dispatch(tagsRequestFailed(error.message));
  }
};
export const loadCountriesList = () => async (dispatch) => {
  dispatch(countriesRequested());
  try {
    const { content } = await dictionaryService.getCountries();
    const countries = transformCountries(content, "Россия");
    dispatch(countriesReceived(countries));
  } catch (error) {
    dispatch(countriesRequestFailed(error.message));
  }
};
export const loadCitiesList = (countryId) => async (dispatch) => {
  dispatch(citiesRequested());
  dispatch(currentCountrySeted(countryId));
  try {
    const { content } = await dictionaryService.getCities(countryId);
    const cities = transformCountries(content, "Новосибирск");
    dispatch(citiesReceived(cities));
  } catch (error) {
    dispatch(citiesRequestFailed(error.message));
  }
};
export const setCurrentCountry = (countryId) => async (dispatch) => {
  dispatch(currentCountrySeted(countryId));
};
export const loadCitiesByCurrentCountryId = (countryId) => async (dispatch) => {
  dispatch(citiesRequested());
  try {
    const { content } = await dictionaryService.getCities(countryId);
    const cities = transformCountries(content, "Новосибирск");
    dispatch(citiesReceived(cities));
  } catch (error) {
    dispatch(citiesRequestFailed(error.message));
  }
};


//tags
export const getDictionaryTags = () => (state) => state.dictionaries.tags;
export const getDictionaryTagsLoadingStatus = () => (state) => state.dictionaries.tagsIsLoading;
export const getDictionaryTagsDataStatus = () => (state) => state.dictionaries.tagsDataLoaded;

//countries
export const getDictionaryCountries = () => (state) => state.dictionaries.countries;
export const getDictionaryCountriesLoadingStatus = () => (state) => state.dictionaries.countriesIsLoading;
export const getDictionaryCountriesDataStatus = () => (state) => state.dictionaries.countriessDataLoaded;
export const getDictionaryCurrentCountry = () => (state) => state.dictionaries.currentCountry;

//cities
export const getDictionaryCities = () => (state) => state.dictionaries.cities;
export const getDictionaryCitiesLoadingStatus = () => (state) => state.dictionaries.citiesIsLoading;
export const getDictionaryCitiesDataStatus = () => (state) => state.dictionaries.citiesDataLoaded;

// export const getNodeById = (id) => (state) => {
//   if (state.dictionaries.entities) {
//     return state.dictionaries.entities.find((node) => node.id === id);
//   }
// };


export default dictionariesReducer;
