import React, { useEffect, useState } from 'react';
import styles from './OAuthPanel.module.css';
import { ReactComponent as AppleSVG } from '../../../assets/apple.svg';
import { ReactComponent as GoogleSVG } from '../../../assets/google.svg';
import { ReactComponent as VKontakeSVG } from '../../../assets/vkontakte.svg';
import { GoogleLogin, useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import queryString from "query-string";
import { useDispatch } from 'react-redux';
import { loginApple, loginGoogle, loginVK } from '../../../store/user';
<script src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/ru_RU/appleid.auth.js" defer></script>

const redirect = '/greeting';
const cbLinkGoogle = `https://mobile.getaclass.ru/login/google`;
const cbLinkVK = `https://mobile.getaclass.ru/login/vk`;
const cbLinkApple = `https://mobile.getaclass.ru/login/apple`;
const GOOGLE_CLIENT_ID = '871294804564-tdmlmobbjrr0d14nul1p7osje016j1e5.apps.googleusercontent.com';
const VK_CLIENT_ID = '51752329';
const APPLE_CLIENT_ID = 'ru.getaclass.sid';

function OAuthPanel() {
  const dispatch = useDispatch();
  const { search, pathname, hash } = useLocation();
  const [provider, setProvider] = useState("");
  /*Google OAuth */
  // const googleLogin = useGoogleLogin({
  //   flow: 'auth-code',
  //   scope: 'email profile',
  //   redirect_uri: 'http://localhost:3000/login',
  //   onSuccess: async (codeResponse) => {
  //     dispatch(loginGoogle({
  //       payload: codeResponse.code,
  //       redirect
  //     }));
  //     console.log(codeResponse);
  //     // const tokens = await axios.post(
  //     //     'http://localhost:3001/auth/google', {
  //     //         code: codeResponse.code,
  //     //     });

  //     // console.log(code);
  //   },
  //   onError: errorResponse => console.log(errorResponse),
  // });
  /*VKontakte OAuth */
  useEffect(() => {
    // console.log("hash", hash);
    const queryObj = queryString.parse(search);
    const parsedHash  = queryString.parse(hash); // {id_token, code}
    if (queryObj.code) {
      if (pathname === "/login/vk") {
        dispatch(loginVK({
          payload:queryObj.code,
          redirect
        }));
      }
      if (pathname === "/login/google") {
        dispatch(loginGoogle({
          payload:queryObj.code,
          redirect
        }));
      }
    }
    if (parsedHash.id_token) {
      if (pathname === "/login/apple") {
        dispatch(loginApple({
            payload: parsedHash.id_token,
            redirect
          }));
      }
    }
  }, [search, provider, hash])
  
  const googleLogin = () => {
    setProvider("google");
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=${GOOGLE_CLIENT_ID}&scope=profile%20email&redirect_uri=${cbLinkGoogle}&access_type=offline&display=touch&response_type=code&include_granted_scopes=true`;
  };
  const vkLogin = () => {
    setProvider("VK");
    window.location.href = `https://oauth.vk.com/authorize?client_id=${VK_CLIENT_ID}&display=mobile&redirect_uri=${cbLinkVK}&scope=email&response_type=code&v=5.131`;
  };
  const appleLogin = async() => {
    setProvider("apple");
    window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${APPLE_CLIENT_ID}&redirect_uri=${cbLinkApple}&response_type=code id_token&response_mode=fragment`;
  };
  return (
    <div className={styles.OAuthPanel}>
      <div className={styles.wrapper}>
        <div className={styles.Header}>Авторизуйтесь при помощи</div>
        <div className={styles.ButtonsList}>
          <div
            onClick={appleLogin}
            className={styles.Btn}
          >
            <AppleSVG />
          </div>
          <div
            className={styles.Btn}
            onClick={googleLogin}
          >
            <GoogleSVG />
          </div>
          <div
            className={styles.Btn}
            onClick={vkLogin}
          >
            <VKontakeSVG />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(OAuthPanel);