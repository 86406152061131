// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterBar_FilterBar__w8-EA {
  background: var(--white);
  padding: 42px 20px 8px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.FilterBar_FilterBar__w8-EA ul {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  overflow-x: scroll;
}

.FilterBar_FilterBar__w8-EA ul::-webkit-scrollbar-track {
  visibility: hidden;
}

.FilterBar_FilterBar__w8-EA ul::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.FilterBar_FilterBar__w8-EA ul::-webkit-scrollbar {
  visibility: hidden;
  width: 1px;
}

.FilterBar_FilterBar__w8-EA .FilterBar_FilterButton__ugssi {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.06);
  color: var(--primary);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  white-space: nowrap;
  border: none;
}

.FilterBar_FilterBar__w8-EA .FilterBar_FilterButton__ugssi.FilterBar_active__dboUu {
  color: var(--white);
  background: var(--primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/FilterBar/FilterBar.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,gBAAgB;EAChB,MAAM;EACN,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,wBAAwB;EACxB,gDAAgD;EAChD,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B","sourcesContent":[".FilterBar {\n  background: var(--white);\n  padding: 42px 20px 8px;\n  position: sticky;\n  top: 0;\n  z-index: 10;\n}\n\n.FilterBar ul {\n  list-style: none;\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 12px;\n  overflow-x: scroll;\n}\n\n.FilterBar ul::-webkit-scrollbar-track {\n  visibility: hidden;\n}\n\n.FilterBar ul::-webkit-scrollbar-thumb {\n  visibility: hidden;\n}\n\n.FilterBar ul::-webkit-scrollbar {\n  visibility: hidden;\n  width: 1px;\n}\n\n.FilterBar .FilterButton {\n  display: flex;\n  padding: 8px 16px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n  background: var(--white);\n  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.06);\n  color: var(--primary);\n  font-family: Open Sans;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n  white-space: nowrap;\n  border: none;\n}\n\n.FilterBar .FilterButton.active {\n  color: var(--white);\n  background: var(--primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FilterBar": `FilterBar_FilterBar__w8-EA`,
	"FilterButton": `FilterBar_FilterButton__ugssi`,
	"active": `FilterBar_active__dboUu`
};
export default ___CSS_LOADER_EXPORT___;
