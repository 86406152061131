import React, { useEffect, useState } from 'react';
import styles from './TaskCard.module.css';
import { ReactComponent as FavoritiesSVG } from '../../../assets/favorities-icon.svg';
import { ReactComponent as CheckSVG } from '../../../assets/completed-icon.svg';
import { useHistory, useLocation } from 'react-router-dom';
import DifficultyBar from '../DifficultyBar';
import { useSelector } from 'react-redux';
import { getCompletedTasks } from '../../../store/user';

export default function TaskCard({ item }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const completedTasksList = useSelector(getCompletedTasks());
  const [completed, setCompleted] = useState(false);

  function initializeStateCompletedTask(completed, task) {
    const initialState = completed?.some(v => v === task?.id);
    setCompleted(initialState);
  };

  useEffect(() => {
    initializeStateCompletedTask(completedTasksList, item);
  }, [item, completedTasksList]);
  // const goToItemPage = (data) => {
  //   const { id, type, parent } = data;
  //   if (type === "subsection") {
  //     history.push(`/${parent}/${id}`);
  //   }
  //   if (type === "section") {
  //     history.push(`/${id}`);
  //   }
  // };
  const goToTaskPage = (item) => {
    history.push(`/tasks/${item.parent}/${item.id}`, {
      from: pathname
    });
  };
  return (
    <div
      className={styles.TaskCard}
      onClick={() => goToTaskPage(item)}
    >
      <div className={styles.wrapper}>
        <label className={styles.Title}>№{item.order}</label>
        {completed && <div className={styles.isWatched}>
          <CheckSVG />
          РЕШЕНА
        </div>}
      </div>
      <label className={styles.Title}>{item.parentName}</label>
      <div className={styles.Description}>
        {item.question}
      </div>
      <DifficultyBar difficulty={item.difficulty}/>
    </div>
  );
}
