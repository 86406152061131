import httpService from "./http.service";
// import localStorageService from "./localStorage.service";

const profileEndpoint = "/UserProfile/";
const favoriteEndpoint = "/UserProfile/Favourite/video/";
const completeTaskEndpoint = "/UserProfile/Complete/task";
const watchedVideoEndpoint = "/UserProfile/Viewed/video";
const userEndpoint = "/UserProfile/User";

const userService = {
  get: async () => {
    const { data } = await httpService.get(profileEndpoint);
    return data;
  },
  update: async (payload) => {
    const { data } = await httpService.post(profileEndpoint, payload);
    return data;
  },
  addFavorite: async (payload) => {
    const { data } = await httpService.post(favoriteEndpoint, payload);
    return data;
  },
  removeFavorite: async (payload) => {
    const { data } = await httpService.delete(favoriteEndpoint, { data: payload });
    return data;
  },
  addCompletedTask: async (payload) => {
    const { data } = await httpService.post(completeTaskEndpoint, payload);
    return data;
  },
  removeCompleteTask: async (payload) => {
    const { data } = await httpService.delete(completeTaskEndpoint, { data: payload });
    return data;
  },
  addWatchedVideo: async (payload) => {
    const { data } = await httpService.post(watchedVideoEndpoint, payload);
    return data;
  },
  removeUserAccount: async (payload) => {
    const { data } = await httpService.delete(userEndpoint, { data: payload });
    return data;
  },
};

export default userService;
