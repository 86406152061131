import React from 'react';
import styles from './ThemePage.module.css';
import ItemsList from '../../ui/ItemsList';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { getThemeById } from '../../../store/themes';
import { getSubThemes } from '../../../store/subthemes';
import { sortItems } from '../../../utils/sortItems';

export default function ThemePage() {
  const history = useHistory();
  const { themeId, subthemeId } = useParams();
  const { pathname } = useLocation();
  const theme = useSelector(getThemeById(Number(themeId)));
  const subthemes = useSelector(getSubThemes())?.filter(el => el.themeId === Number(themeId));
  const state = history?.location?.state?.from ?? null;
  if (subthemes.length === 1) {
    //переход при одном элементе aubtheme сразу на её сраницу
    history.push(`/video/${theme?.chapterId}/${themeId}/${subthemes[0]?.id}`, {
      from: history?.location?.state?.from
    });
  }
  const goBack = () => {
    if (state) {
      history.push(state);
    } else {
      history.push(`/video/${theme.chapterId}`);
    }
  };
  
  const sortedThemes = sortItems(subthemes);
  return (
    <div className={styles.ThemePage} >
      <div className={styles.Header}>
        <div
          className={styles.HeaderButton}
          onClick={goBack}
        >
          <BackArroowIcon className={styles.BackArroowIcon} />
        </div>
        <div
          className={styles.HeaderButton}
          onClick={() => history.push(`${pathname}/info`)}
        >
          <InfoIcon className={styles.InfoIcon} />
        </div>
      </div>
      <div className={styles.wrapper} >
        {theme && <h2 className={styles.Title}>{theme.title}</h2>}
      </div>
      <div className={styles.List} >
        {sortedThemes ? <ItemsList items={sortedThemes} type="subthemes" /> : <ItemsList items={[1, 2, 3]} type='skeletons' styleCard={{ minHeight: "158px" }}/>}
      </div>
    </div>
  )
}
