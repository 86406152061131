// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OAuthPanel_OAuthPanel__IixYq {
  background: var(--white, #fff);
  padding: 32px 40px;
  width: 100%;
}

.OAuthPanel_OAuthPanel__IixYq .OAuthPanel_Header__7WzRw {
  color: var(--black-80, rgba(15, 18, 27, 0.80));
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-bottom: 24px;
}

.OAuthPanel_OAuthPanel__IixYq .OAuthPanel_wrapper__vGWZX {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OAuthPanel_OAuthPanel__IixYq .OAuthPanel_ButtonsList__JtzwJ {
  display: flex;
  justify-content: space-evenly;
}

.OAuthPanel_OAuthPanel__IixYq .OAuthPanel_Btn__ACZGG {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary, #1E2536);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/OAuthPanel/OAuthPanel.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,8CAA8C;EAC9C,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mCAAmC;EACnC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".OAuthPanel {\n  background: var(--white, #fff);\n  padding: 32px 40px;\n  width: 100%;\n}\n\n.OAuthPanel .Header {\n  color: var(--black-80, rgba(15, 18, 27, 0.80));\n  font-family: 'Open Sans';\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n  text-align: center;\n  margin-bottom: 24px;\n}\n\n.OAuthPanel .wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.OAuthPanel .ButtonsList {\n  display: flex;\n  justify-content: space-evenly;\n}\n\n.OAuthPanel .Btn {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  background: var(--primary, #1E2536);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--white, #fff);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OAuthPanel": `OAuthPanel_OAuthPanel__IixYq`,
	"Header": `OAuthPanel_Header__7WzRw`,
	"wrapper": `OAuthPanel_wrapper__vGWZX`,
	"ButtonsList": `OAuthPanel_ButtonsList__JtzwJ`,
	"Btn": `OAuthPanel_Btn__ACZGG`
};
export default ___CSS_LOADER_EXPORT___;
