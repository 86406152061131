// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChapterPage_ChapterPage__6ShGk {
  padding-bottom: 72px;
  min-height: 100vh;
  background: var(--background, #F9F9F9);
}

.ChapterPage_ChapterPage__6ShGk .ChapterPage_wrapper__C1zIv {
  padding: 0px 20px 24px;
  background: var(--background, #F9F9F9);
}

.ChapterPage_ChapterPage__6ShGk .ChapterPage_Header__SY1rY {
  height: 44px;
  padding: 0px 20px 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background: var(--background, #f9f9f9);
  z-index: 10;
}

.ChapterPage_ChapterPage__6ShGk .ChapterPage_HeaderButton__iSgy0 {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ChapterPage_ChapterPage__6ShGk .ChapterPage_Title__iQVVQ {
  color: var(--black);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px
}

.ChapterPage_ChapterPage__6ShGk .ChapterPage_HeaderButton__iSgy0:nth-child(2) {
  flex-direction: row-reverse;
}
.ChapterPage_BackArroowIcon__KtNaX,
.ChapterPage_InfoIcon__iAUlY {
  width: 24px;
  height: 24px;
}

.ChapterPage_ChapterPage__6ShGk .ChapterPage_BackArroowIcon__KtNaX path {
  color: var(--medium-gray);
}

.ChapterPage_ChapterPage__6ShGk .ChapterPage_InfoIcon__iAUlY path {
  stroke: var(--medium-gray);
  fill: none;
}

.ChapterPage_ChapterPage__6ShGk .ChapterPage_List__8Qq5E {
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/ChapterPage/ChapterPage.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;EACtC,WAAW;AACb;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB;AACF;;AAEA;EACE,2BAA2B;AAC7B;AACA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".ChapterPage {\n  padding-bottom: 72px;\n  min-height: 100vh;\n  background: var(--background, #F9F9F9);\n}\n\n.ChapterPage .wrapper {\n  padding: 0px 20px 24px;\n  background: var(--background, #F9F9F9);\n}\n\n.ChapterPage .Header {\n  height: 44px;\n  padding: 0px 20px 0;\n  position: sticky;\n  top: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n  background: var(--background, #f9f9f9);\n  z-index: 10;\n}\n\n.ChapterPage .HeaderButton {\n  width: 30%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.ChapterPage .Title {\n  color: var(--black);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px\n}\n\n.ChapterPage .HeaderButton:nth-child(2) {\n  flex-direction: row-reverse;\n}\n.BackArroowIcon,\n.InfoIcon {\n  width: 24px;\n  height: 24px;\n}\n\n.ChapterPage .BackArroowIcon path {\n  color: var(--medium-gray);\n}\n\n.ChapterPage .InfoIcon path {\n  stroke: var(--medium-gray);\n  fill: none;\n}\n\n.ChapterPage .List {\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChapterPage": `ChapterPage_ChapterPage__6ShGk`,
	"wrapper": `ChapterPage_wrapper__C1zIv`,
	"Header": `ChapterPage_Header__SY1rY`,
	"HeaderButton": `ChapterPage_HeaderButton__iSgy0`,
	"Title": `ChapterPage_Title__iQVVQ`,
	"BackArroowIcon": `ChapterPage_BackArroowIcon__KtNaX`,
	"InfoIcon": `ChapterPage_InfoIcon__iAUlY`,
	"List": `ChapterPage_List__8Qq5E`
};
export default ___CSS_LOADER_EXPORT___;
