import React, { useEffect } from 'react';
import styles from './Profile.module.css';
import logo from '../../../assets/Logo.webp';
import { navItems } from '../../../utils/navItems';
import Header from '../../ui/Header';
import ItemsList from '../../ui/ItemsList';
import { profileItems } from '../../../utils/profileItems';
import Navbar from '../../ui/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, getUserProfileStatus, loadUserProfile } from '../../../store/user';

export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfile());
  const dataUserProfileStatus = useSelector(getUserProfileStatus());
  useEffect(() => {
    if (!dataUserProfileStatus) dispatch(loadUserProfile());
  }, [dataUserProfileStatus, dispatch]);
  if (!profile) return;
  const { firstName, surname } = profile;
  let label = `${firstName} ${surname ?? ""}`;
  if (!firstName && !surname) {
    label = "Пользователь"
  }
  return (
    <>
      <div className={styles.Profile}>
        <Header label={label} logo={logo} />
        <div className={styles.Wrapper}>
          <ItemsList items={profileItems} type="simples" />
        </div>
      </div>
      <Navbar navItems={navItems} />
    </>

  )
}
