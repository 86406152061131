import React from 'react';
import ReactDom from 'react-dom';
import styles from './PopUp.module.css';

export default function PopUp({ onClose, content, alt, open, label, text }) {
  if (!open) {
    return null;
  }
  return ReactDom.createPortal(
    <>
      <div
        className={styles.Overlay}
        onClick={(e) => {
          e.stopPropagation();
          onClose(false);
        }}
      />
      <div className={styles.PopUp} >
        <div className={styles.Wrapper}>
          {label && <h2 className={styles.Title}>{label}</h2>}
          {text && <div className={styles.Text}>{text}</div>}
          <img
            className={styles.Text}
            src={content}
            alt={alt}
            loading="eager"
          />
        </div>
        <button
          className={styles.PopUpCloseBtn}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        >&times;</button>
      </div>
    </>,
    document.getElementById('popup'));
}
