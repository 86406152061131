import React, { useState } from 'react';
import styles from './RadioField.module.css';
import { ReactComponent as OpenedEye } from '../../../../assets/opened-eye.svg';
import { ReactComponent as ClosedEye } from '../../../../assets/closed-eye.svg';

export default function RadioField({ onChange, style, option, value, name, onCancel }) {
  const handleChange = ({ target }) => {
    onChange({ name: target.name, value: target.value });
    onCancel();
  };
  return (
    <>
      <div
        className={styles.RadioField}
        style={style}
      >
        <label
          className={styles.RadioLabel}
          htmlFor={option.label + "_" + option.value}
        >
        <input
          className={styles.RadioInput}
          type="radio"
          name={name}
          id={option.label + "_" + option.value}
          checked={option.value == value}
          value={option.value}
          onChange={handleChange}
        />
          {option.label}
        </label>
      </div>
    </>
  );
}