import React, { useEffect, useState } from 'react';
import styles from './LoginForm.module.css';
import TextField from '../../common/form/TextField';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validator } from '../../../utils/validator';
import { getAuthErrors, login } from '../../../store/user';

export default function LoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [isTouched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const loginError = useSelector(getAuthErrors());

  const validatorConfig = {
    email: {
      isRequired: {
        message: "Пожалуйста, заполните поля"
      },
      isEmail: {
        message: "Неправильный email или пароль"
      }
    },
    password: {
      isRequired: {
        message: "Пожалуйста, заполните поля"
      },
      isCapitalSymbol: {
        message: "В пароле должна быть одна заглавная буква"
      },
      isContainDigit: {
        message: "Пароль должен содержать хотя бы одну цифру"
      },
      minLength: {
        message: "Пароль должен состоять минимум из 8 символов",
        value: 8
      },
      maxLength: {
        message: "Пароль должен состоять максимум из 25 символов",
        value: 25
      }
    }
  };

  // useEffect(() => {
  //   setIsValid(false);
  // }, []);
  useEffect(() => {
    if (isTouched) setIsValid(validate(data, validatorConfig));
  }, [data, isTouched]);

  const validate = (data, config) => {
    const errors = validator(data, config);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate(data, validatorConfig);
    if (!data) return;
    if (!isValid) return;
    const redirect = "/";
    dispatch(login({ payload: data, redirect }));
  };

  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value
    }));
  };
  
  const onToggle = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.LoginForm}>
      <TextField
        placeholder="Email"
        name="email"
        value={data.email}
        onChange={handleChange}
        style={{ marginBottom: '12px' }}
        isError={!isValid}
        // onFocus={() => setTouched(false)}
        onBlur={() => setTouched(true)}
      />
      <TextField
        placeholder="Пароль"
        type="password"
        name="password"
        value={data.password}
        onChange={handleChange}
        isError={!isValid}
        // onFocus={() => setTouched(false)}
        onBlur={() => setTouched(true)}
        showPassword={showPassword}
        toggleShowPassword={onToggle}
      />
      {!isValid && isTouched ? <p className={styles.ErrorMessage}>{Object.values(errors)[0]}</p> : null}
      {loginError && <p className={styles.ErrorMessage}>{loginError}</p>}
      <button
        style={{ marginTop: isValid ? '40px' : '20px' }}
        type="submit"
        disabled={!isValid}
        className={styles.SubmitButton}
      >Войти</button>
    </form>
  );
}
