import React, { useEffect, useState } from 'react';
import styles from './OnBoarding.module.css';
import logo from '../../../assets/onboarding-logo.webp';
import qr from '../../../assets/QR.webp';

import { slides } from '../../../utils/slides';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsLoggedIn } from '../../../store/user';
import useMediaQuery from "../../../hooks/useMediaQuery";
import PopUp from '../../ui/PopUp';

function useMediaQueries() {
  const mobile = useMediaQuery("(max-width: 1025px)");

  return { mobile };
}

export default function OnBoarding() {
  const history = useHistory();
  const { mobile } = useMediaQueries();
  const [page, setPage] = useState(slides[0]);
  const [showQR, setShowQR] = useState(false);
  const isLoggedin = useSelector(getIsLoggedIn());
  const state = history?.location?.state?.from ?? null;
  // useEffect(() => {
  //   if (isLoggedin) {
  //     history.push("/video");
  //   }
  // }, [isLoggedin, history]);
  useEffect(() => {
    if (!mobile) {
      setShowQR(true);
    } else {
      setShowQR(false);
    }
  }, [mobile]);
  const switchSlide = () => {
    setPage(prev => {
      if (prev.id === slides.length) {
        return slides[0];
      }
      const nextIndex = prev.id;
      return slides[nextIndex];
    });
  };

  const goToPage = () => {
    if (state) {
      history.push(state);
    } else {
      history.replace('/login/login')
    }
  };

  return (
    <>
      <div className={styles.OnBoarding}>
        <div className={styles.Header}>
          <button
            className={styles.SkipButton}
            onClick={goToPage}
          >Пропустить</button>
        </div>
        <div className={styles.CountPage}>
          <div className={styles.TotalPages}>
            <div className={styles.ActivePage}>{page.id}{" "}</div>
            {` / ${slides.length}`}
          </div>
        </div>
        <h2 className={styles.Title}>{page.title}</h2>
        <div
          className={styles.Description}
        >
          {page.description}
          {page.anchor && <a href={page.anchor} target="_blank" rel="noreferrer"><span className={styles.anchor}>{page.anchor}</span></a>}
        </div>
        <div
          className={page.id === slides.length ? styles.SponsorWrap : styles.LogoWrap}
        >
          <img
            className={page.id === slides.length ? styles.SponsorLogo : styles.Logo}
            src={page.image}
            alt={page.alt}
          />
        </div>

        {(page.id === slides.length)
          ?
            state
            ? <button
                className={styles.Btn}
                onClick={goToPage}
              >В профайл</button>
            : <button
                className={styles.Btn}
                onClick={goToPage}
              >Войти</button>
          :
          <button
            className={styles.Btn}
            onClick={switchSlide}
          >Далее</button>}
        {showQR &&
          <PopUp
            open={showQR}
            content={qr}
            alt="QR-код сайта mobile.getaclass.ru"
            text="Приложение доступно для скачивания в RuStore, скоро появится в Google play и Apple Store. А также можно перейти по QR-коду."
            // label="Заголовок"
            onClose={() => setShowQR(false)}
          />
        }
      </div>
    </>
  )
}
