import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './VideoPage.module.css';
import ItemsList from '../../ui/ItemsList';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as BackArroowIcon } from '../../../assets/back-arrow.svg';
import { ReactComponent as FavoritiesSVG } from '../../../assets/favorities-icon.svg';
import { ReactComponent as CheckSVG } from '../../../assets/completed-icon.svg';
import { getVideoById, getVideos } from '../../../store/videos';
import { getSubThemeById, toggleDisplayList } from '../../../store/subthemes';
import { getThemeById } from '../../../store/themes';
import { addFavoriteVideo, addWatchedVideo, deleteFavoriteVideo, getFavoritesVideos, getIsLoggedIn, getWatchedVideos } from '../../../store/user';
import YouTube from 'react-youtube';
import AnimateHeight from "react-animate-height";
import { loadTasksList } from '../../../store/tasks';

const SECONDS_IN_MINUTE = 60;
function transformDurationVideo(seconds) {
  let durationMinutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  let durationSeconds = seconds % SECONDS_IN_MINUTE;
  if (durationSeconds < 10) {
    durationSeconds = "0" + durationSeconds;
  }

  return { minutes: durationMinutes, seconds: durationSeconds }
};

export default function VideoPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { themeId, lessonId, videoId } = useParams();
  const { pathname } = useLocation();
  const video = useSelector(getVideoById(videoId));
  const favoritesVideosList = useSelector(getFavoritesVideos());
  const watchedVideosList = useSelector(getWatchedVideos());
  const [isFavorites, setFavorites] = useState(false);
  const [isWatched, setWatched] = useState(false);
  const [height, setHeight] = useState(38);
  const subtheme = useSelector(getSubThemeById(video?.subThemeId));
  const theme = useSelector(getThemeById(subtheme?.themeId));
  const duration = transformDurationVideo(video?.durationSeconds);
  let recomendedVideos;
  const state = history?.location?.state?.from ?? null;
  const videos = useSelector(getVideos())?.filter(video => !!video.subThemeId);
  const isLoggedIn = useSelector(getIsLoggedIn());

  function initializeStateFavorites(favorites, video) {
    const initialState = favorites?.some(v => v === video?.id);
    setFavorites(initialState);
  };
  function initializeStateWatched(watched, video) {
    const initialState = watched?.some(v => v === video?.id);
    setWatched(initialState);
  };

  useEffect(() => {
    initializeStateFavorites(favoritesVideosList, video);
  }, [video, favoritesVideosList]);
  useEffect(() => {
    initializeStateWatched(watchedVideosList, video);
  }, [video, watchedVideosList]);
  useEffect(() => {
    setHeight(38);
  }, [video]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [video]);

  if (!video) {
    return null;
  }
  const { name, tags, urlPart, description } = video;
  const renderTagList = () => {
    return tags.map((tag, idx) => (
      <span
        key={idx}
        className={styles.TagElement}
      >
        {tag}
      </span>
    ))
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const getRandomVideos = (videos) => {
    const totalArray = [];
    for (let i = 0; i < 3; i++) {
      let index = getRandomInt(videos.length);
      totalArray.push(videos[index]);
    }
    return totalArray;
  };

  const handleClickVideo = (event) => {
    if (isLoggedIn) dispatch(addWatchedVideo({ id: video?.id }));
    // event.target.playVideo();
  };
  const handleAddFavoriteVideo = (item) => {
    if (!isFavorites) {
      dispatch(addFavoriteVideo({id: item?.id}));
      setFavorites(true);
    } else {
      dispatch(deleteFavoriteVideo({id: item?.id}));
      setFavorites(false);
    }
  };
  const goToPage = () => {
    if (state) {
      history.push(state);
    } else {
      history.push(`/video/${theme?.parent}/${subtheme?.parent}/${video?.subThemeId}`);
    }
  };

  return (
    <div className={styles.VideoPage} >
      <div className={styles.Header}>
        <div
          className={styles.HeaderButton}
          onClick={goToPage}
        >
          <BackArroowIcon className={styles.BackArroowIcon} />
        </div>
        <div className={styles.Icon} onClick={() => {
          if (isLoggedIn) {
            handleAddFavoriteVideo(video);
          }
        }}>
          <FavoritiesSVG className={`${isFavorites ? styles.Favorites : ""}`} />
        </div>
      </div>
      <div className={styles.Video}>
        {/* <iframe
          // onClick={handleChange}
          // ref={rootChanged}
          src={`https://www.youtube-nocookie.com/embed/${urlPart}`}
          allowFullScreen
          title={name}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe> */}
        <YouTube
          onPlay={handleClickVideo}
          videoId={urlPart}
          opts={{
            playerVars: {
              autoplay: 0,
              controls: 1,
            }
          }}
          title={name}
        ></YouTube>
      </div>
      <div className={styles.wrapper} >
        {video && <h2 className={styles.Title}>{video?.title}</h2>}
        <div className={styles.Info}>
          <div className={styles.Duration}>{`${duration.minutes}:${duration.seconds}` ?? "00:00"}</div>
          {isWatched && <div className={styles.isWatched}>
            <CheckSVG />
            ПРОСМОТРЕНО
          </div>}
        </div>
        { tags.length !== 0 ? (
            // <div style={{ display: "flex" }}>
            <div>
              <AnimateHeight
                id="example-panel"
                duration={800}
                height={height}
              >
                <div className={styles.TagList}>
                  { renderTagList() }
                </div>
              </AnimateHeight>
              <button
                onClick={() => setHeight(height === 38 ? "auto" : 38)}
                className={styles.DropDown}
              >
                {height === 38 ? "Развернуть теги" : "Свернуть теги"}
              </button> 
            </div>
          ) : null }
        {/* <div className={styles.TagList}>
          {tags && renderTagList()}
        </div> */}
        <div className={styles.Description}>
          {description}
        </div>
        {/* <div className={styles.Anchor} onClick={() => history.push("#")}> */}
        {video?.hasSummary && <div
          className={styles.Anchor}
          onClick={() => history.push(`${pathname}/summary`)}
        >
          Смотреть конспект
        </div>}
      </div>
      <div className={styles.SecondWrapper} >
        <div className={styles.List} >
          <div className={styles.Another} >
            ДРУГИЕ УРОКИ
          </div>
          {videos && <ItemsList type='mini' items={getRandomVideos(videos)} />}
          {/* <ItemsList type='mini' items={recomendedVideos ?? getRandomVideos(videos)} /> */}
        </div>
        <button
          className={styles.Btn}
          onClick={(e) => {
            e.preventDefault();
            dispatch(loadTasksList(video?.subThemeId));
            dispatch(toggleDisplayList(false));
            history.push(`/video/${theme?.parent}/${subtheme?.parent}/${video?.subThemeId}`);
          }}
        >Перейти к задачам</button>
      </div>
    </div>
  )
}
