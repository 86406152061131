import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import LoginForm from '../../ui/LoginForm';
import OAuthPanel from '../../ui/OAuthPanel';
import SignUpForm from '../../ui/SignUpForm';
import PrivacyPolicy from '../../ui/PrivacyPolicy';
import styles from './Login.module.css';
import Recovery from '../../ui/Recovery';
import { privacyData } from '../../../utils/privacyData';

// const privacyData = {
//   name: 'Политика в отношении обработки персональных данных',
//   description: 'Под «Сайтом» или «Интернет-ресурсом Компании» понимается веб-сайт mobile.getaclass.ru с учетом всех уровней доменных имен, принадлежащий Компании. Под «персональными данными» понимается любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (гражданину). Под «обработкой персональных данных» понимается любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без использования таких средств. К таким действиям (операциям) можно отнести: сбор, получение, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.',
//   backPath: '/login/register'
// };
const recoveryData = {
  name: 'Забыли пароль?',
  description: 'Для восстановления, пожалуйста, введите электронный адрес, указанный при регистрации в системе GetAClass.',
  backPath: '/login/login'
};

export default function Login() {
  const { type } = useParams();
  // const [formType, setFormType] = useState(type === "register" ? type : "login");
  const [formType, setFormType] = useState(type);
  
  const toggleFormType = (path) => {
    // setFormType(prevState => prevState === "register" ? "login" : "register");
    setFormType(path);
  };
  return (
    <div className={styles.Login}>
      {formType === "register"
        ?
        <>
          <h2 className={styles.Header}>Приветствуем!</h2>
          <p className={styles.Greeting}>У вас уже есть аккаунт? <Link to={`/login/login`} onClick={() => toggleFormType("login")} >Войдите здесь</Link></p>
          <SignUpForm />
          <p className={styles.Greeting}>Нажимая кнопку «Создать аккаунт», вы соглашаетесь с  <Link to={`/login/privacy`} onClick={() => toggleFormType("privacy")}>Политикой обработки данных</Link></p>
          <OAuthPanel />
        </>
        : formType === "recovery"
          ?
          <>
            <Recovery item={recoveryData} onChangeContent={setFormType} />
          </>
          : formType === "privacy"
            ?
            <>
              <PrivacyPolicy item={privacyData} onChangeContent={setFormType} />
            </>
            :
            <>
              <h2 className={styles.Header}>Приветствуем!</h2>
              <p className={styles.Greeting}>Ещё нет аккаунта?  <Link to={`/login/register`} onClick={() => toggleFormType("register")} >Зарегистрироваться</Link></p>
              <LoginForm />
              <p className={styles.Greeting}>Забыли пароль?  <Link to={`/login/recovery`} onClick={() => toggleFormType("recovery")}>Восстановите его</Link></p>
              <OAuthPanel />
            </>
      }
    </div>
  )
}
