import { createSlice } from "@reduxjs/toolkit";
import themeService from "../services/theme.service";
import { transformThemes } from "../utils/transformNodes";
// import nds from "./response.json";

const themesSlice = createSlice({
  name: "themes",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
    dataLoaded: false
  },
  reducers: {
    themesRequested: (state) => {
      state.isLoading = true;
    },
    themesReceived: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    themesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.dataLoaded = false;
      state.isLoading = false;
    }
  }
});

const { actions, reducer: themesReducer } = themesSlice;
const { themesRequested, themesReceived, themesRequestFailed } = actions;

export const loadThemesList = () => async (dispatch) => {
  dispatch(themesRequested());
  try {
    const { content } = await themeService.fetchAll();
    const data = transformThemes(content);
    dispatch(themesReceived(data));
  } catch (error) {
    dispatch(themesRequestFailed(error.message));
  }
};

export const getThemes = () => (state) => state.themes.entities;
export const getThemesLoadingStatus = () => (state) =>
  state.themes.isLoading;
export const getThemesDataStatus = () => (state) => state.themes.dataLoaded;
export const getThemeById = (id) => (state) => {
  if (state.themes.entities) {
    return state.themes.entities.find((theme) => theme.id === id);
  }
};


export default themesReducer;
