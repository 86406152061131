import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import styles from './PDFViewer.module.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


export default function PDFViewer({ urlPDF }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div
      className={styles.PDFViewer}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={urlPDF}
          plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    </div>
  )
}
