import React, { useState } from 'react';
import styles from './CheckBoxField.module.css';
import { ReactComponent as OpenedEye } from '../../../../assets/opened-eye.svg';
import { ReactComponent as ClosedEye } from '../../../../assets/closed-eye.svg';

export default function CheckBoxField({ onChange, style, option, value, name }) {
  const handleChange = ({ target }) => {
    onChange({ name: target.name, value: target.value });
  };
  return (
    <>
      <div
        className={styles.CheckBoxField}
        style={style}
      >
        <label
          className={styles.CheckBoxLabel}
          htmlFor={option.label + "_" + option.value}
        >
        <input
          className={styles.CheckBoxInput}
          type="checkbox"
          name={name}
          id={option.label + "_" + option.value}
          checked={value.includes(String(option.value))}
          value={option.value}
          onChange={handleChange}
        />
          {option.label}
        </label>
      </div>
    </>
  );
}